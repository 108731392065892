function setActiveIndexByID(activeModelName, id) {
    let currentViewer = window.CI360._viewers.find(
        (x) => x.id === activeModelName
    );
    if (currentViewer) {
        currentViewer.activeImageX = id
        let canvas = currentViewer["canvas"];
        if (canvas) {
            let context = canvas.getContext("2d");
            let selectedImage =
                currentViewer.imagesX[currentViewer.activeImageX];
            if (selectedImage) {
                context.clearRect(0, 0, canvas.width, canvas.height);
                context.drawImage(
                    selectedImage,
                    0,
                    0,
                    canvas.width,
                    canvas.height
                );
            }
        }
    }
}
window.CI360.setActiveIndexByID = setActiveIndexByID;
