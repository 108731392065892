var __commonjs_global = typeof window !== 'undefined' ? window : typeof global !== 'undefined' ? global : this;
function __commonjs(fn, module) { return module = { exports: {} }, fn(module, module.exports, __commonjs_global), module.exports; }

var babelHelpers = {};
babelHelpers.typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
  return typeof obj;
} : function (obj) {
  return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
};

babelHelpers.classCallCheck = function (instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
};

babelHelpers.inherits = function (subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
};

babelHelpers.possibleConstructorReturn = function (self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
};

var count = 0;

function noop() {}

function jsonp(url, opts, fn) {
    if ('function' == typeof opts) {
        fn = opts;
        opts = {};
    }
    if (!opts) opts = {};

    var prefix = opts.prefix || '__jp';

    // use the callback name that was passed if one was provided.
    // otherwise generate a unique name by incrementing our counter.
    var id = opts.name || prefix + count++;

    var param = opts.param || 'callback';
    var timeout = null != opts.timeout ? opts.timeout : 60000;
    var enc = encodeURIComponent;
    var target = document.getElementsByTagName('script')[0] || document.head;
    var script;
    var timer;

    if (timeout) {
        timer = setTimeout(function () {
            cleanup();
            if (fn) fn(new Error('Timeout'));
        }, timeout);
    }

    function cleanup() {
        if (script.parentNode) script.parentNode.removeChild(script);
        window[id] = noop;
        if (timer) clearTimeout(timer);
    }

    function cancel() {
        if (window[id]) {
            cleanup();
        }
    }

    window[id] = function (data) {
        cleanup();
        if (fn) fn(null, data);
    };

    // add qs component
    url += (~url.indexOf('?') ? '&' : '?') + param + '=' + enc(id);
    url = url.replace('?&', '?');

    // create script
    script = document.createElement('script');
    script.src = url;
    target.parentNode.insertBefore(script, target);

    return cancel;
}

var docReady = __commonjs(function (module, exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports["default"] = docReady;

    function docReady(callback) {

        function completed() {
            document.removeEventListener("DOMContentLoaded", completed, false);
            window.removeEventListener("load", completed, false);
            callback();
        }

        //Events.on(document, 'DOMContentLoaded', completed)

        if (document.readyState === "complete") {
            // Handle it asynchronously to allow scripts the opportunity to delay ready
            setTimeout(callback);
        } else {

            // Use the handy event callback
            document.addEventListener("DOMContentLoaded", completed, false);

            // A fallback to window.onload, that will always work
            window.addEventListener("load", completed, false);
        }
    }

    module.exports = exports["default"];
});

var DocumentReady = docReady && (typeof docReady === "undefined" ? "undefined" : babelHelpers.typeof(docReady)) === 'object' && 'default' in docReady ? docReady['default'] : docReady;

var _this = this;

var config$1 = config = {
    // the form element query selector
    formClass: '.shopping-tools--wtl form',
    // formClass: '.webtolead-2019 .shopping-tools--wtl form',

    // url parameter prefix injector
    customParameterPrefix: 'custom_',

    // date format for pikaday
    dateFormat: 'DD[/]MM[/]YYYY',

    // DOM formElement Node getter
    formElement: function formElement() {
        return document.querySelector(config.formClass);
    },

    // locale 2 chars format
    language: function language() {
        if (config.formElement().hasAttribute('data-language')) {
            var val = config.formElement().getAttribute('data-language');
            if (val !== null && val !== '') {
                return val;
            }
        }

        return 'en';
    },

    // countrycode, number format
    countryCode: function countryCode() {
        if (config.formElement().hasAttribute('data-countryCode')) {
            var val = config.formElement().getAttribute('data-countryCode');
            if (val !== null && val !== '') {
                return val;
            }
        }
    },

    // dealercode preselection
    dealerCode: function dealerCode() {
        if (config.formElement().hasAttribute('data-dealer')) {
            var val = config.formElement().getAttribute('data-dealer');
            if (val !== null && val !== '') {
                return val;
            }
        }

        return false;
    },

    // dealers filter
    zipCode: function zipCode() {
        if (config.formElement().hasAttribute('data-zip')) {
            var val = config.formElement().getAttribute('data-zip');
            if (val !== null && val !== '') {
                return val;
            }
        }

        return -1;
    },

    lightbox: {
        // lightbox pages query selector
        pagesClassPrefix: '.lightbox-page--',

        // controls class
        controlsClass: '.lightbox-controls',

        // activates strict mode for lightbox (no next until the page is valid)
        strict: true,

        isInjectable: function isInjectable() {
            if (config.formElement().hasAttribute('data-lightbox')) {
                var val = config.formElement().getAttribute('data-lightbox');
                if (val !== null && val !== '') {
                    return val === 'true';
                }
            }

            return false;
        }
    },
    adobeTracker: {
        enabled: true,
        type: 'lead',
        isEnabled: function isEnabled() {
            return _this.enabled && typeof digitalData !== 'undefined' && typeof _satellite !== 'undefined';
        }
    }
};

var Element = function () {

    /**
     * Attach some custom WTL functions to a DOM Node
     * @param element the DOM node
     * @param options object defining some custom behaviors @see doc
     */
    function Element(element) {
        var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        babelHelpers.classCallCheck(this, Element);

        this._element = element;
        this._options = options;
        this._trackingData = undefined;

        // bind custom events
        this._initCustomEvents();

        // init object options
        this._initOptions();

        // required class
        this._setRequiredClass();

        // required aria
        this._setRequiredAria();

        // save tracking data
        this._saveTrackingData();

        // global validation
        this._globalValidationTrigger();

        // custom error string
        this._getCustomErrorMessage();

        // check empty
        this._initCheckValue();

        // focus
        this._initFocus();
    }

    Element.prototype._initCheckValue = function _initCheckValue() {
        this.attachEvent('blur', this.checkValue.bind(this));
    };

    Element.prototype._initFocus = function _initFocus() {
        this.attachEvent('focus', this.setFocus.bind(this));
    };

    Element.prototype._initOptions = function _initOptions() {
        if (typeof this._options.value !== 'undefined') {
            this.setValue(this._options.value);
        }
    };

    Element.prototype._setRequiredClass = function _setRequiredClass() {
        if (this.isMandatory()) {
            this._element.classList.add('required-field');
        } else {
            this._element.classList.remove('required-field');
        }
    };

    Element.prototype._setRequiredAria = function _setRequiredAria() {
        if (this.isMandatory()) {
            this._element.setAttribute('aria-required', 'true');
        } else {
            this._element.setAttribute('aria-required', 'false');
        }
    };

    Element.prototype._hasTrackingData = function _hasTrackingData() {
        return this._element.hasAttribute('data-trackingfield');
    };

    Element.prototype._saveTrackingData = function _saveTrackingData() {
        if (this._hasTrackingData()) {
            this._trackingData = this._element.getAttribute('data-trackingfield');
        }
    };

    /**
     * Removes all the tracking attributes
     */


    Element.prototype.removeTrackingData = function removeTrackingData() {
        this._element.removeAttribute('data-trackingfield');
    };

    /**
     * Resets all the tracking attributes
     */


    Element.prototype.addTrackingData = function addTrackingData() {
        if (typeof this._trackingData !== 'undefined') {
            this._element.setAttribute('data-trackingfield', this._trackingData);
        }
    };

    Element.prototype._globalValidationTrigger = function _globalValidationTrigger() {
        this.attachEvent('blur', this.validate.bind(this));
    };

    Element.prototype._initCustomEvents = function _initCustomEvents() {
        var _this = this;

        if (typeof this._options.events !== 'undefined') {
            this._options.events.forEach(function (event) {
                _this.attachEvent(event.type, event.fn);
            }, this);
        }
    };

    Element.prototype._testRule = function _testRule() {
        if (babelHelpers.typeof(this._options.rule) === 'object') return this._options.rule.test(this.getValue());
        if (typeof this._options.rule === 'function') return this._options.rule();
        return true;
    };

    /**
     * Toggles the class 'error' on the DOM Node
     * @param condition set or unset the class
     */


    Element.prototype.setErrorClass = function setErrorClass(condition) {
        if (condition) {
            this._element.classList.remove('error');
        } else {
            this._element.classList.add('error');
        }
    };

    Element.prototype.setAlertRoles = function setAlertRoles(condition) {
        if (!this._element.classList.contains('_hasvalue') && this._element.parentElement.getElementsByClassName('error-message').length > 0) {
            this._element.removeAttribute("aria-errormessage");
            this._element.parentElement.getElementsByClassName('error-message')[0].removeAttribute("id");
            this._element.parentElement.getElementsByClassName('error-message')[0].removeAttribute("role");
        }
        if (condition) {
            this._element.removeAttribute("aria-invalid");

            if (this._element.parentElement.getElementsByClassName('error-message').length > 0) {
                this._element.removeAttribute("aria-errormessage");
                this._element.parentElement.getElementsByClassName('error-message')[0].removeAttribute("id");
                this._element.parentElement.getElementsByClassName('error-message')[0].removeAttribute("role");
            }
        } else {
            var elementId = this._element.id;

            this._element.setAttribute("aria-invalid", "true");

            if (this._element.parentElement.getElementsByClassName('error-message').length > 0 && this._element.classList.contains('_hasvalue')) {
                this._element.setAttribute("aria-errormessage", "err-" + elementId);
                this._element.parentElement.getElementsByClassName('error-message')[0].setAttribute("id", "err-" + elementId);
                this._element.parentElement.getElementsByClassName('error-message')[0].setAttribute("role", "alert");
            }
        }
    };

    /**
     * Return the custom error string or false
     * @private
     */


    Element.prototype._getCustomErrorMessage = function _getCustomErrorMessage() {
        var dataset = this._element.dataset.error;
        if (typeof dataset !== 'undefined' && dataset !== '') {
            this.errorMessage = dataset;
        } else {
            this.errorMessage = false;
        }
    };

    /**
     * Toggles the class '_hasvalue' on the DOM Node
     * @param condition set or unset the class
     */


    Element.prototype.setHasValueClass = function setHasValueClass(condition) {
        if (condition) {
            this._element.classList.add('_hasvalue');
        } else {
            this._element.classList.remove('_hasvalue');
        }
    };

    /**
     * Checks if the field has been set as 'mandatory'
     * @returns {boolean|*}
     */


    Element.prototype.isMandatory = function isMandatory() {
        // if the option was not set
        if (typeof this._options.mandatory === 'undefined') {
            return false;
        }

        // if the options is a function's result
        if (typeof this._options.mandatory === 'function') {
            return this._options.mandatory.call();
        }

        // in any other case
        return this._options.mandatory;
    };

    /**
     * Wraps the DOM Node .value() function
     * @returns {*}
     */


    Element.prototype.getValue = function getValue() {
        return this._element.value;
    };

    /**
     * Wraps the DOM Node .setAttribute() function
     * @returns {*}
     */


    Element.prototype.setValue = function setValue(value) {
        this._element.setAttribute('value', value);
    };

    /**
     * Wraps the DOM Node .addEventListener() function
     * @returns {*}
     */


    Element.prototype.attachEvent = function attachEvent(type, fn) {
        this._element.addEventListener(type, fn);
    };

    /**
     * Pure validation
     * @returns {boolean}
     */


    Element.prototype.isValid = function isValid() {
        // if the field is compiled, then test it against the rule
        if (typeof this.getValue() !== 'undefined' && $.trim(this.getValue()) !== '') {
            return this._testRule();
        }

        // if the field is not compiled then test against the mandatory option
        return this.isMandatory() ? $.trim(this.getValue()) !== '' : true;
    };

    /**
     * Validates the DOM Node against the rules and sets the error class if needed
     * @returns {boolean}
     */


    Element.prototype.validate = function validate() {
        var isValid = this.isValid();

        // set the element error
        this.setErrorClass(isValid);

        // set the element error
        this.setAlertRoles(isValid);

        return isValid;
    };

    /**
     * Check if the element is not empty and sets the _hasvalue class if needed
     * @returns {boolean}
     */


    Element.prototype.checkValue = function checkValue() {
        var hasValue = this._element.value != "";

        // set the element _hasvalue
        this.setHasValueClass(hasValue);

        return hasValue;
    };

    /**
     * Remove the _hasvalue class (to be used in combination with focus in event)
     */


    Element.prototype.setFocus = function setFocus() {
        this.setHasValueClass(true);
    };

    return Element;
}();

// var endPointCountryList = window.contextEnvironment.maseratiWsEndpoint +'/comserv/public/wtl/countryList';



AsyncPrivacy = window.AsyncPrivacy = function (_Element) {
    babelHelpers.inherits(AsyncPrivacy, _Element);

    function AsyncPrivacy(element, options) {
        babelHelpers.classCallCheck(this, AsyncPrivacy);

        var _this = babelHelpers.possibleConstructorReturn(this, _Element.call(this, element, options));

        _this.isSubmittingValidation = false;
        _this._create();
        return _this;
    }

    AsyncPrivacy.prototype.validate = function validate() {
        this.isSubmittingValidation = true;
        this._register.forEach(function (e) {
            e.dispatchEvent(new CustomEvent('change'));
        });

        return this._isPrivacyValid();
    };

    AsyncPrivacy.prototype._created = function _created() {
        if (typeof config$1.countryCode() !== 'undefined') {
            // get query string
            this._updateQueryString();

            // call the requested services
            jsonp(this._queryString, false, this._updateData.bind(this));
        }
    };

    AsyncPrivacy.prototype._mounted = function _mounted() {
        // since we are ready, bind my validation triggers to the submit button (if any)
        this._options.submitElement.addEventListener('submit', this._onSubmit.bind(this));
        this._overlayTab();
        //MASERATI.Wcag.formEvents();
    };

    AsyncPrivacy.prototype._updatedError = function _updatedError() {
        this._renderError();
    };

    AsyncPrivacy.prototype._updated = function _updated() {
        if (!this.rendered) this._render();
        this._overlayTab();
        //MASERATI.Wcag.formEvents();
    };

    AsyncPrivacy.prototype._updateQueryString = function _updateQueryString() {
        this._queryString = typeof this._options.webService === 'function' ? this._options.webService() : this._options.webService;
        var lang = config$1.language();
        if (config$1.countryCode() === '180' && config$1.language() === 'zh') {
            // force chinese traditional for Taiwan
            lang = 'zh_HANT';
        }
        this._queryString += "?country=" + config$1.countryCode() + "&lang=" + lang + "&form_type=guest";
    };

    AsyncPrivacy.prototype._updateData = function _updateData(error, data) {
        this._data = data.result;
        this._err = data.errorCode;

        if (this._err == "0" || this._err == 0) {
            this._element.dispatchEvent(new CustomEvent('updated'));
        } else {
            this._element.dispatchEvent(new CustomEvent('updatedError'));
        }
    };

    AsyncPrivacy.prototype._create = function _create() {
        this._element.addEventListener('created', this._created.bind(this));
        this._element.addEventListener('mounted', this._mounted.bind(this));
        this._element.addEventListener('updated', this._updated.bind(this));
        this._element.addEventListener('updatedError', this._updatedError.bind(this));

        // set as created
        this._element.dispatchEvent(new CustomEvent('created'));
    };

    AsyncPrivacy.prototype._reset = function _reset() {
        // validation array
        this._validationChecks = {};

        // reset element in DOM
        this._element.innerHTML = '';

        // component register
        this._register = [];
    };

    AsyncPrivacy.prototype._addValidationRule = function _addValidationRule(name, message) {
        this._validationChecks[name] = {
            valid: false,
            message: message
        };
    };

    AsyncPrivacy.prototype._onSubmit = function _onSubmit(e) {

        this.isSubmittingValidation = true;
        this._register.forEach(function (e) {
            e.dispatchEvent(new CustomEvent('change'));
        });

        var previousErrors = this._options.submitElement.querySelectorAll('input.error, select.error, .chkGroup.error');
        var asyncPrivacyErrors = this._element.querySelectorAll('.error');

        // there are some errors before async-privacy
        if (previousErrors.length - asyncPrivacyErrors.length > 0) {
            this._emitValidate(this._options.defaultError);
            this.isSubmittingValidation = false;
            return false;
        } else {
            var error = this._getFirstError();
            var errorsDiv = document.querySelector('.errorsDiv');
            if (!error.valid) {
                if (errorsDiv !== null) errorsDiv.parentElement.removeChild(errorsDiv);
                this._emitError(error.message);
                e.preventDefault();
                this.isSubmittingValidation = false;
                return false;
            }
        }
        return true;
    };

    AsyncPrivacy.prototype._validate = function _validate(element) {
        if (typeof this._validationChecks[element] !== 'undefined' && this._validationChecks[element] != null) {
            this._validationChecks[element].valid = true;
            this._checkValidations();
        }
    };

    AsyncPrivacy.prototype._reject = function _reject(element) {
        if (typeof this._validationChecks[element] !== 'undefined' && this._validationChecks[element] != null) {
            this._validationChecks[element].valid = false;
            this._checkValidations();
        }
    };

    AsyncPrivacy.prototype._checkValidations = function _checkValidations() {
        if (this._isPrivacyValid()) this._emitValidate(this._options.defaultError);
    };

    AsyncPrivacy.prototype._isPrivacyValid = function _isPrivacyValid() {
        return this._getFirstError().valid;
    };

    AsyncPrivacy.prototype._getIsoCode = function _getIsoCode() {
        // define the async promise
        // var resolver = void 0;
        // var rejecter = void 0;
        // var promise = new Promise(function (resolve, reject) {
        //     resolver = resolve;
        //     rejecter = reject;
        // });
        // var endPointCountryList = window.contextEnvironment.maseratiWsEndpoint +'/comserv/public/wtl/countryList';
        // jsonp(endPointCountryList, false, function (error, data) {
        //     if (error) rejecter();

        //     resolver(data.result.countryList.map(function (element) {
        //         return { code: element.countryCode, iso: element.isoCode2 };
        //     }).concat([{ code: '1000', iso: 'international' }]).find(function (value) {
        //         return value.code === config$1.countryCode().toString();
        //     })?.iso.toLowerCase());
        // });

        // return promise.catch(function () {
        //     console.log('Something wrong');
        // });
        return fetch('../../../assets/countries.json')
            .then(response => response.json())
            .then(countryList => {
            const country = countryList.countryList.find(z => z.countryCode == config$1.countryCode());
            if (country) {
                return country.isoCode2.toLowerCase();
            }
            throw new Error('Country not found');
       });
    };

    AsyncPrivacy.prototype._getFirstError = function _getFirstError() {
        for (var check in this._validationChecks) {
            if (this._validationChecks.hasOwnProperty(check) && !this._validationChecks[check].valid) return this._validationChecks[check];
        }

        return { valid: true, message: this._options.defaultError };
    };

    AsyncPrivacy.prototype._emitError = function _emitError(message) {
        // console.log('emitting error', this._element, message)
        this._element.dispatchEvent(new CustomEvent('error', { detail: { message: message } }));
    };

    AsyncPrivacy.prototype._emitValidate = function _emitValidate(message) {
        // console.log('emitting validation', this._element, message)
        this._element.dispatchEvent(new CustomEvent('validate', { detail: { message: message } }));
    };

    /** RENDERING ERROR TEMPLATE **/


    AsyncPrivacy.prototype._renderError = function _renderError() {
        var node = this._element;
        var root = $(node).parentsUntil('.shopping-tools--wtl').last();
        // hide loading animation and fade-in form
        root.removeClass('form-loading');
        $(root).addClass("privacy-haserror");
    };

    /** RENDERING TEMPLATE **/

    AsyncPrivacy.prototype._render = function _render() {
        var _this2 = this;

        var node = this._element;

        // copy styles
        node.setAttribute('class', this._element.getAttribute('class'));

        // to prevent double privacy policy in WTL international
        if (typeof this._storePromise === 'undefined') {
            this._storePromise = new Promise(function (resolve, reject) {
                return resolve();
            });
        }

        // sub renders
        this._storePromise.then(function () {
            _this2._storePromise = _this2._getIsoCode().then(function (isoCode) {
                _this2._reset();
                return isoCode;
            }).then(function (isoCode) {
                _this2._renderPrivacyCommonTextTo(node, _this2._data.privacyCommonText, isoCode);
                _this2._renderPrivacyDirectMarketingTo(node, _this2._data.privacyDirectMarketing);
                _this2._renderPrivacyProfilingTo(node, _this2._data.privacyProfiling);
                _this2._renderPrivacyUserDataTo(node, _this2._data.privacyUserData);

                // finally set as mounted
                _this2._element.dispatchEvent(new CustomEvent('mounted'));
            });
        });
        this.rendered = true;
    };

    AsyncPrivacy.prototype._renderPrivacyUserDataTo = function _renderPrivacyUserDataTo(node, options) {
        var root = $(node).parentsUntil('.shopping-tools--wtl').last();
        if (root.length <= 0) return false;

        // name
        var name = options.name || '';
        root.find('input[name=name]').val(name);

        // surname
        var surname = options.surname || '';
        root.find('input[name=surname]').val(surname);

        // email
        var email = options.email || '';
        root.find('input[name=email]').val(email);

        // language
        var preferredLanguage = options.preferredLanguage || '';
        if ($("select[name=preferredLanguage]:not(.updated)").length > 0) {
            root.find("select[name=preferredLanguage]:not(.updated)").addClass("updated").val(preferredLanguage).change();

            var evt = document.createEvent("HTMLEvents");
            evt.initEvent("change", false, true);
            document.querySelector('select[name="preferredLanguage"]').dispatchEvent(evt);
        }

        // mobile
        var mobile = options.mobile || '';
        root.find('input[name=mobile]').val(mobile).change();

        // phone
        var phone = options.phone || '';
        root.find('input[name=phone]').val(phone).change();

        // hide loading animation and fade-in form
        root.removeClass('form-loading');
    };

    AsyncPrivacy.prototype._renderPrivacyCommonTextTo = function _renderPrivacyCommonTextTo(node, options, isoCode) {
        var _this3 = this;

        var privacyLinkShortURL = '<span class="tos-trigger" data-overlay="true" tabindex="0" role="button">' + options.clickForPrivacyPolicy + '</span>';
        var privacyOverlay = '<span class="close" tabindex="0" role="button" aria-label="close dialog"></span>' + '<div class="description-container">' + '<div class="description">' + '<div class="textimage component left">' + '<div class="text">' + '<div class="description">' + options.mainPrivacyPolicy + '</div>' + '</div>' + '</div>' + '</div>' + '</div>';

        // render di mainPrivacyPolicy
        if (typeof options.mainPrivacyPolicy !== 'undefined' && options.mainPrivacyPolicy !== null && options.mainPrivacyPolicy.trim() !== '') {
            var policy = document.getElementById('legal-notes') || document.createElement('div');
            policy.id = 'legal-notes';
            policy.classList.add('privacy-common-text__policy');
            policy.setAttribute('role', 'dialog');
            policy.setAttribute('aria-labelledby', 'privacy-overlay__title');
            policy.setAttribute('aria-describedby', 'privacy-overlay__description');
            policy.setAttribute('tabindex', '0');
            policy.innerHTML = privacyOverlay;
            $(policy).children('span.close').click(function () {
                $('.privacy-common-text__policy').removeClass('opened');
                $('.redesign-menu-main-wrap').removeClass('hidden');
                document.body.classList.remove('locked');
                $('span.tos-trigger').focus();
            });
            // append to body
            if (!policy.parentElement) document.body.appendChild(policy);
        }

        // render di mainTitle
        if (typeof options.mainTitle !== 'undefined' && options.mainTitle !== null && options.mainTitle.trim() !== '') {
            var title = document.createElement('div');
            title.classList.add('question');
            title.classList.add('privacy-common-text__title');
            title.innerText = options.mainTitle;
            // append to node
            node.appendChild(title);
        }

        // render di mainIntro
        if (typeof options.mainIntro !== 'undefined' && options.mainIntro !== null && options.mainIntro.trim() !== '') {
            var subTitle = document.createElement('div');
            subTitle.classList.add('privacy');
            var subTitleParagraph = document.createElement('p');
            subTitleParagraph.classList.add('privacy-common-text__sub-title');
            subTitleParagraph.innerHTML = options.mainIntro.replace('${clickForPrivacyPolicy}', privacyLinkShortURL);
            $(subTitleParagraph).children('.tos-trigger').click(function () {
                $('.privacy-common-text__policy').addClass('opened');
                $('.redesign-menu-main-wrap').removeClass('hidden');
                document.body.classList.add('locked');
                $('.privacy-common-text__policy').focus();
            });

            // append subTitleParagraph to subTitle
            subTitle.appendChild(subTitleParagraph);

            // append to node
            node.appendChild(subTitle);
        }

        // mainIntroTextArea
        if (typeof options.mainIntroTextArea !== 'undefined' && options.mainIntroTextArea !== null && options.mainIntroTextArea.trim() !== '') {
            var textArea = document.createElement('div');
            textArea.classList.add('privacy');
            textArea.classList.add('text-area-border');
            var textAreaParagraph = document.createElement('p');
            textAreaParagraph.classList.add('privacy-common-text__sub-title');
            textAreaParagraph.classList.add('privacy-common-text__sub-title--limited');
            textAreaParagraph.innerHTML = options.mainIntroTextArea;

            // append subTitleParagraph to subTitle
            textArea.appendChild(textAreaParagraph);

            // append to node
            node.appendChild(textArea);
        }

        // hiddenSectionSentence
        if (typeof options.hiddenSectionSentence !== 'undefined' && options.hiddenSectionSentence !== null && options.hiddenSectionSentence.trim() !== '') {
            // docs say that you need to hide other things..
            node.classList.add('micro-privacy');

            // descriptive string build up
            var getDescriptiveString = function getDescriptiveString(sentence, replace) {
                if (typeof replace !== 'undefined' && replace !== null && replace.trim() !== '') {
                    return sentence.replace('${hiddenSectionPH}', '<a href="#" class="hidden-section-sentence-trigger">' + replace + '</a>');
                }
                return sentence;
            };

            // row node
            var hiddenSectionSentenceRow = document.createElement('div');
            hiddenSectionSentenceRow.classList.add('row');
            hiddenSectionSentenceRow.classList.add('hidden-section-sentence-row');

            // define some things
            var hiddenSectionSentenceName = 'hiddenSentenceCheckbox';

            // create checkbox
            var littlePrivacyInput = document.createElement('input');
            littlePrivacyInput.setAttribute('type', 'checkbox');
            littlePrivacyInput.setAttribute('id', hiddenSectionSentenceName);
            littlePrivacyInput.setAttribute('name', hiddenSectionSentenceName);
            littlePrivacyInput.setAttribute('value', 'Yes');

            // validation
            var validation = function validation() {
                if (littlePrivacyInput.checked) {
                    _this3._validate('littlePrivacyValidation');
                    littlePrivacyInput.classList.remove('error');
                } else {
                    _this3._reject('littlePrivacyValidation');
                    littlePrivacyInput.classList.add('error');
                }
            };
            littlePrivacyInput.addEventListener('change', validation.bind(this));

            if (options.mandatoryValue) this._addValidationRule('littlePrivacyValidation', ''); // TODO

            this._register.push(littlePrivacyInput);

            // single channel label
            var label = document.createElement('label');
            label.classList.add('checkBoxDescription');
            label.setAttribute('for', hiddenSectionSentenceName);
            label.innerHTML = '<span>' + getDescriptiveString(options.hiddenSectionSentence, options.hiddenSectionPH) + '</span>';

            // if mandatory prints <div class="required">*</div> else not required
            var mandatory = document.createElement('div');
            mandatory.classList.add('required');
            mandatory.innerText = '*';

            // add the infamous event
            var trigger = label.querySelector('.hidden-section-sentence-trigger');
            trigger.addEventListener('click', function (e) {
                e.preventDefault();
                littlePrivacyInput.checked = true;
                littlePrivacyInput.dispatchEvent(new CustomEvent('change'));
                node.classList.remove('micro-privacy');
            });

            // appending
            hiddenSectionSentenceRow.appendChild(mandatory);
            hiddenSectionSentenceRow.appendChild(littlePrivacyInput);
            hiddenSectionSentenceRow.appendChild(label);
            node.appendChild(hiddenSectionSentenceRow);
        }
    };

    AsyncPrivacy.prototype._renderPrivacyDirectMarketingTo = function _renderPrivacyDirectMarketingTo(node, options) {
        var _this4 = this;

        if (options.visible) {
            // base consent
            // row node
            var consentRow = document.createElement('div');
            consentRow.classList.add('privacy-direct-marketing');

            // chkGroup div
            var chkGroup = document.createElement('div');
            chkGroup.setAttribute('id', 'privacyChk');
            chkGroup.classList.add('chkGroup');

            // intro text
            var introText = document.createElement('div');
            introText.classList.add('intro-text');
            introText.classList.add('privacy');
            var introTextParagraph = document.createElement('p');
            introTextParagraph.innerText = options.introText;
            if (introTextParagraph.innerText == "null") introTextParagraph.innerText = "";
            // append intro-text to the node
            introText.appendChild(introTextParagraph);

            // append intro-text to the row
            consentRow.appendChild(introText);

            // required
            var requiredRow = document.createElement('div');
            requiredRow.innerText = '*';
            requiredRow.classList.add('required');

            // not required
            if (typeof options.mandatoryValue === 'undefined' || options.mandatoryValue === 'null' || options.mandatoryValue === null) requiredRow.classList.add('not');

            // append to row
            consentRow.appendChild(requiredRow);

            // chk
            var consentChk = document.createElement('input');
            consentChk.setAttribute('type', 'checkbox');
            consentChk.setAttribute('id', 'privacy_direct_marketing--yes');
            consentChk.setAttribute('value', 'Yes');
            consentChk.setAttribute('name', 'privacy_direct_marketing');

            // validation rule
            if (options.mandatoryValue) this._addValidationRule('privacyDirectMarketing', options.warnValidationFail);

            // change event
            var consentChange = function consentChange() {
                if (consentChk.checked) $("label[for='" + consentChk.id + "']").attr("aria-checked", true);else $("label[for='" + consentChk.id + "']").attr("aria-checked", false);
                if (consentChk.checked && (options.mandatoryValue === 'false' || options.mandatoryValue === false) || // true is checked but mandatory value is false
                options.mandatoryValue !== 'null' && options.mandatoryValue !== null && !consentChk.checked) {
                    // any|true|false mandatory value but no one is checked

                    _this4._reject('privacyDirectMarketing');
                    chkGroup.classList.add('error');
                } else {
                    _this4._validate('privacyDirectMarketing');
                    chkGroup.classList.remove('error');
                }
            };
            consentChk.addEventListener('change', consentChange.bind(this));

            // on submit check validation
            this._register.push(consentChk);

            // label
            var privacyLinkShortURL = '<span class="tos-trigger" data-overlay="true" tabindex="0" role="button">' + options.clickForPrivacyPolicy + '</span>';
            var channelsLink = '<br><span class="channel-trigger" tabindex="0" role="button">' + options.clickForChannelText + '</span>';
            var consentLabel1 = document.createElement('label');
            var consentLabel2 = document.createElement('label');
            consentLabel1.setAttribute('for', 'privacy_direct_marketing--yes');
            consentLabel1.setAttribute('tabindex', '0');
            consentLabel1.setAttribute('role', 'checkbox');
            consentLabel1.setAttribute('aria-checked', false);
            consentLabel1.classList.add('checkBoxDescription');
            consentLabel2.classList.add('checkBoxDescription');
            var labelText = options.label || '';
            labelText = labelText.replace('${clickForChannelText}', channelsLink);
            labelText = labelText.replace('${clickForPrivacyPolicy}', privacyLinkShortURL);
            // consentLabel1.innerHTML = '&nbsp;'
            consentLabel1.innerHTML = '';
            consentLabel2.innerHTML = '<span>' + labelText + '</span>';

            // preselection
            if (typeof options.presetValue !== 'undefined') {
                if (options.presetValue === 'true' || options.presetValue === true) {
                    consentChk.setAttribute('checked', 'checked');
                    consentChk.dispatchEvent(new CustomEvent('change'));
                }
            }

            // append checkbox and label to chkGroup container
            chkGroup.appendChild(consentChk);
            chkGroup.appendChild(consentLabel1);
            chkGroup.appendChild(consentLabel2);

            // append chkGroup to consentRow
            consentRow.appendChild(chkGroup);

            // append to node
            node.appendChild(consentRow);

            // channel list
            // channels user's preselection
            this._channelsUserSelection = {};

            // container
            var channelsBox = document.createElement('div');
            channelsBox.classList.add('channels');
            channelsBox.classList.add('privacy-direct-marketing--channels');

            // show channelsBox
            var showChannels = function showChannels() {
                if ($(channelsBox).is(':visible')) {
                    $(channelsBox).hide();
                } else {
                    $(channelsBox).show();
                    if (!consentChk.checked) $('.privacy-direct-marketing--channels').addClass('disabled');
                }

                $(window).resize();
            };

            // set channelsBox values
            var setChannels = function setChannels() {
                if (!_this4.isSubmittingValidation) {
                    if (consentChk.checked) {
                        // all channels true
                        $(channelsBox).find('input[type=checkbox]').each(function () {
                            this.checked = true;
                            this.dispatchEvent(new CustomEvent('change'));
                        });
                        $('.privacy-direct-marketing--channels').removeClass('disabled');
                    } else {
                        // all channels false
                        $(channelsBox).find('input[type=checkbox]').each(function () {
                            this.checked = false;
                            this.dispatchEvent(new CustomEvent('change'));
                        });
                        $('.privacy-direct-marketing--channels').addClass('disabled');
                    }
                }
            };

            // channels must work only if the consent is set TRUE
            var renderChannels = function renderChannels() {
                if (options.channelListVisible && Object.keys(options.channelList).length > 0) {
                    _this4._renderPrivacyDirectMarketingChannelsTo(channelsBox, options);
                }
            };

            var channelsClickElement = $('span.channel-trigger');
            channelsClickElement.click(showChannels);

            $(consentChk).change(setChannels);

            // append to node
            node.appendChild(channelsBox);

            renderChannels();
        }
    };

    AsyncPrivacy.prototype._renderPrivacyDirectMarketingChannelsTo = function _renderPrivacyDirectMarketingChannelsTo(node, options) {
        var _this5 = this;

        // channels
        var channels = Object.keys(options.channelList);

        // add general rule
        if (options.mandatoryValue) this._addValidationRule('privacyDmChannels', options.warnMissingChannel);

        // checkBoxes check function
        var hasSomeChecked = function hasSomeChecked() {
            var checkBoxes = node.querySelectorAll('input[type="checkbox"]');
            for (var i = 0; i < checkBoxes.length; i++) {
                if (checkBoxes[i].checked) return true;
            }

            return false;
        };

        // error handling
        var checkBoxesCheck = function checkBoxesCheck() {
            // at least one must be checked
            if (hasSomeChecked()) _this5._validate('privacyDmChannels');else _this5._reject('privacyDmChannels');
        };

        var _loop = function _loop(i) {
            // console.log('siamo nel ciclo for')

            // an inline-block div container for each checkbox
            var channelBox = document.createElement('div');
            channelBox.classList.add('inlineblock');

            var channel = options.channelList[channels[i]];
            var channelInput = document.createElement('input');

            if (channel.visible) {
                channelInput.setAttribute('type', 'checkbox');
                channelInput.setAttribute('id', channel.fieldName);
                channelInput.setAttribute('name', channel.fieldName);
                channelInput.setAttribute('value', 'Yes');

                // hidden input to ensure post of unchecked checkboxes (mandatory: false)
                var hiddenCheckbox = document.createElement('input');
                hiddenCheckbox.setAttribute('type', 'hidden');
                hiddenCheckbox.setAttribute('name', channel.fieldName);
                hiddenCheckbox.setAttribute('value', 'No');
                channelBox.appendChild(hiddenCheckbox);

                // :ribbon: maserati's backend customization
                channelInput.addEventListener('change', function () {
                    if (channelInput.checked) $("label[for='" + channelInput.id + "']").attr("aria-checked", true);else $("label[for='" + channelInput.id + "']").attr("aria-checked", false);

                    if (channelBox.querySelector('input[type="hidden"]') !== null && channelInput.checked) {
                        hiddenCheckbox.parentElement.removeChild(hiddenCheckbox);
                    } else if (channelBox.querySelector('input[type="hidden"]') === null && !channelInput.checked) {
                        channelBox.appendChild(hiddenCheckbox);
                    }
                });

                // preselect
                if (channel.presetValue === 'true' || channel.presetValue === true || _this5._channelsUserSelection[channel.fieldName]) {
                    channelInput.checked = true;
                    channelInput.dispatchEvent(new CustomEvent('change'));
                }

                // channel user selection data
                var saveChannelSelection = function saveChannelSelection() {
                    _this5._channelsUserSelection[channel.fieldName] = channelInput.checked;
                };
                channelInput.addEventListener('change', saveChannelSelection.bind(_this5));

                // multiple channel validation
                channelInput.addEventListener('change', checkBoxesCheck.bind(_this5));

                // single channel validation
                _this5._addValidationRule('#CHANNEL#' + channel.fieldName, channel.warnValidationFail);
                var singleChannelValidation = function singleChannelValidation() {
                    if ((channel.mandatoryValue === 'true' || channel.mandatoryValue === true) && !channelInput.checked || (channel.mandatoryValue === 'false' || channel.mandatoryValue === false) && channelInput.checked) {
                        _this5._reject('#CHANNEL#' + channel.fieldName);
                        channelInput.classList.add('error');
                    } else {
                        _this5._validate('#CHANNEL#' + channel.fieldName);
                        channelInput.classList.remove('error');
                    }
                };
                channelInput.addEventListener('change', singleChannelValidation.bind(_this5));

                // trigger change event
                if (channel.mandatoryValue !== 'true' && channel.mandatoryValue !== true) {
                    channelInput.dispatchEvent(new CustomEvent('change'));
                }

                // on submit check validation
                _this5._register.push(channelInput);

                // single channel label
                var label = document.createElement('label');
                label.classList.add('checkBoxDescription');
                label.setAttribute('for', 'privacy_dm_channel_' + channels[i]);
                label.setAttribute('tabindex', '0');
                label.setAttribute('role', 'checkbox');
                label.setAttribute('aria-checked', false);
                label.innerHTML = '<span>' + channel.label + '</span>';

                // if mandatory prints <div class="required">*</div> else not required
                var mandatory = document.createElement('div');
                if (channel.mandatoryValue !== 'true' && channel.mandatoryValue !== true) mandatory.classList.add('not');
                mandatory.classList.add('required');
                mandatory.innerText = '*';

                // appending
                channelBox.appendChild(mandatory);
                channelBox.appendChild(channelInput);
                channelBox.appendChild(label);
                node.appendChild(channelBox);
            } else {
                channelInput.setAttribute('type', 'hidden');
                channelInput.setAttribute('name', channel.fieldName);
                channelInput.setAttribute('value', channel.mandatoryValue === 'true' || channel.mandatoryValue === true ? 'Yes' : 'No');

                // appending
                node.appendChild(channelInput);
            }
        };

        for (var i = 0; i < channels.length; i++) {
            _loop(i);
        }
    };

    AsyncPrivacy.prototype._renderPrivacyProfilingTo = function _renderPrivacyProfilingTo(node, options) {
        var _this6 = this;

        if (options.visible) {
            var container = document.createElement('div');
            container.classList.add('privacy-profiling');

            //chkGroup div
            var chkGroup = document.createElement('div');
            chkGroup.setAttribute('id', 'contactChk');
            chkGroup.classList.add('chkGroup');

            // intro text
            var introText = document.createElement('div');
            introText.classList.add('intro-text');
            introText.classList.add('privacy');
            var introTextParagraph = document.createElement('p');
            introTextParagraph.innerText = options.introText;
            if (introTextParagraph.innerText == "null") introTextParagraph.innerText = "";
            // append intro-text to the node
            introText.appendChild(introTextParagraph);

            // required * graphics
            var mandatoryDiv = document.createElement('div');
            mandatoryDiv.classList.add('required');
            mandatoryDiv.innerText = '*';

            // if is not required set the correct class
            if (typeof options.mandatoryValue === 'undefined' || options.mandatoryValue === 'null' || options.mandatoryValue === null) {
                mandatoryDiv.classList.add('not');
            }

            container.appendChild(mandatoryDiv);

            // checkbox
            var trueInput = document.createElement('input');

            // yes way
            trueInput.setAttribute('type', 'checkbox');
            trueInput.setAttribute('name', 'privacy_profiling');
            trueInput.setAttribute('id', 'privacy_profiling--yes');
            trueInput.setAttribute('value', 'Yes');
            trueInput.setAttribute('data-trackingfield', 'marketing privacy');

            // validation
            if (options.mandatoryValue) this._addValidationRule('privacyProfiling', options.warnValidationFail);

            // change event
            var consentChange = function consentChange() {
                if (trueInput.checked) $("label[for='" + trueInput.id + "']").attr("aria-checked", true);else $("label[for='" + trueInput.id + "']").attr("aria-checked", false);

                if (trueInput.checked && (options.mandatoryValue === 'false' || options.mandatoryValue === false) || // true is checked but mandatory value is false
                options.mandatoryValue !== 'null' && !trueInput.checked) {
                    // any|true|false mandatory value but no one is checked
                    // console.log('consentYesChange mandatory false')
                    _this6._reject('privacyProfiling');
                    chkGroup.classList.add('error');
                } else {
                    _this6._validate('privacyProfiling');
                    chkGroup.classList.remove('error');
                }
            };
            trueInput.addEventListener('change', consentChange.bind(this));

            // on submit check validation
            this._register.push(trueInput);

            // labels
            var privacyLinkShortURL = '<span class="tos-trigger" data-overlay="true" tabindex="0" role="button">' + options.clickForPrivacyPolicy + '</span>';
            var consentLabel1 = document.createElement('label');
            var consentLabel2 = document.createElement('label');
            consentLabel1.setAttribute('for', 'privacy_profiling--yes');
            consentLabel1.setAttribute('tabindex', '0');
            consentLabel1.setAttribute('role', 'checkbox');
            consentLabel1.setAttribute('aria-checked', false);
            consentLabel1.classList.add('checkBoxDescription');
            consentLabel2.classList.add('checkBoxDescription');
            var labelText = options.label || '';
            labelText = labelText.replace('${clickForPrivacyPolicy}', privacyLinkShortURL);
            // consentLabel1.innerHTML = '&nbsp;'
            consentLabel1.innerHTML = '';
            consentLabel2.innerHTML = '<span>' + labelText + '</span>';

            // preselection
            if (typeof options.presetValue !== 'undefined') {
                if (options.presetValue === 'true' || options.presetValue === true) {
                    trueInput.setAttribute('checked', 'checked');
                    trueInput.dispatchEvent(new CustomEvent('change'));
                }
            }

            // append controls
            chkGroup.appendChild(trueInput);
            chkGroup.appendChild(consentLabel1);
            chkGroup.appendChild(consentLabel2);

            // append chkGroup to container
            container.appendChild(chkGroup);

            // append intro-text to the row
            node.appendChild(introText);

            // append row to the node
            node.appendChild(container);
        }
    };

    AsyncPrivacy.prototype._overlayTab = function _overlayTab() {
        var el = $('#legal-notes');
        var focusableElements = $('#legal-notes').find("span.close, [href]").filter(":visible");
        var firstElement = $('#legal-notes').is("span.close, [href]") ? $('#legal-notes') : focusableElements.first();
        var lastElement = focusableElements.last();

        var KEYCODE_TAB = 9;
        el.off("keydown").on("keydown", function (event) {
            if (event.key === 'Tab' || event.keyCode === KEYCODE_TAB) {
                if (event.shiftKey) /* shift + tab */{
                        if ($(document.activeElement).is(firstElement)) {
                            event.preventDefault();
                            lastElement.focus();
                        }
                    } else /* tab */{
                        if ($(document.activeElement).is(lastElement)) {
                            event.preventDefault();
                            firstElement.focus();
                        }
                    }
            } else if (event.keyCode == 27) //esc
                el.find(".close").click();
        });
    };

    return AsyncPrivacy;
}(Element);

