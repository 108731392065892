// Queue is an array with extend function
(function($){
  'use strict';

  var Log = new Logger("EngineQueue");
  Log.mute( ! window.LOG_ENGINE );

  // Queue constructor, return an array structure
  window.Queue = function(engine, items){
    this._engine = engine;
    this.data = [];
    if (items) {
      items = $.isArray(items) ? items : [items];
      $.each(items, function(i,item){
        this.insert(item);
      }, this);
    }
    return this;
  };


  // Flush queue obj
  Queue.prototype.flush = function(){
    this.data = [];
    return this;
  };

  function getId(item){
    var id = null;
    if ( typeof ref === "string" ) {
      id = ref;
    } else {
      id = ref.id;
    }
    return id
  };


  // Remove all obj in queue from given item-position
  Queue.prototype.removeFrom = function(index){
    index > -1 && (this.data.length = index);
  };

  // Return all obj in queue
  Queue.prototype.all = function(){
    return this.data;
  };


  // Return first unread
  Queue.prototype.unparsed = function(){
    for ( var i = 0, item; item = this.data[i]; i++ ){
      if ( ! item.parsed ) {
        item.parsed=true
        return item;
      }
    }
    return null;
  };


  // Check if queue is empty
  Queue.prototype.isEmpty = function(){
    return this.data.length <= 0;
  };


  // Insert an item after an item with a given id
  Queue.prototype.insertAfter = function(item, ref){
    var id = null;
    if ( typeof ref === "string" ) {
      id = ref;
    } else {
      id = ref.id;
    }
    var position = this.indexOfID(id);
    if ( position > -1 ) {
      this.data.splice(position, 0, item);
    }
    return this;
  };


  // Insert one item into the FIFO queue
  Queue.prototype.insert = function(item){
    if (!this.contains(item)){
      this.data.push(item);
      return true;
    }
    return false;
  };


  // Check if the queue contains an ID
  Queue.prototype.contains = function(item) {
    var id;
    if (typeof item === "string"){
      id = item;
    } else {
      id = item.id;
    }

    for (var i =0, l=this.data.length; i<l; i++) {
      if (this.data[i].hasOwnProperty("id")) {
        if (this.data[i].id === id){
          return true;
        }
      }
    }
    return false;
  };


  // Return the position of the element with a given ID
  Queue.prototype.indexOf = function(item) {
    var id;
    if (typeof item === "string"){
      id = item;
    } else {
      id = item.id;
    }

    for (var i =0, l=this.data.length; i<l; i++) {
      if (this.data[i].hasOwnProperty("id")) {
        if (this.data[i].id === id){
          return i;
        }
      }
    }
    return -1;
  };


  // Get item from the queue
  Queue.prototype.getItem = function(item){
    var position = this.indexOf(item);
    if (position > -1 ){
      return this.data[position];
    }
    return null;
  }


  // Flag an item in queue as parsed
  Queue.prototype.flagAsParsed = function(item){
    var id = null;
    if ( typeof item == "string" ) {
      id = item;
    } else {
      id = item.id;
    }
    var position = this.indexOf(id);
    if (this.data[position].parsed === false){
      this.data[position].parsed = true;
      return true;
    }
    return false;
  };


})(jQuery);

(function($){
  'use strict';

  var Log = new Logger("EngineRuleMngr");
  Log.mute( ! window.LOG_ENGINE );

  // Bootstrap for rules and manager for filter rules by various filters
  var RuleManager = window.RuleManager = function (engine, data) {

    // Rules & OPT Database
    this.rulesACE = TAFFY(data.rules);
    this.rulesCOLOR = TAFFY(data.colorRules);


    // rules Q9 simplification (temporary workaround)
    // TODO: in progress, need documentation
    function rulesQ9Simplification(rules,rulesCOLOR){
      var optsRuledByQ9 = [];
      optsRuledByQ9 = rulesCOLOR({type:"Q9"}).distinct("optDest");
      for (var i=0; i < optsRuledByQ9.length; i++) {
        var optRuledByQ9 = optsRuledByQ9[i];
        var rulesToSimplify = rulesCOLOR({type: "Q9",optDest: optRuledByQ9,colSrc2: "", optSrc2:{"!is":""}}).get();
        if (rulesToSimplify.length > 0) {
          var optConditions = [];
          for (var y=0; y < rulesToSimplify.length; y++) {
            var ruleToSimplify = rulesToSimplify[y];
            //console.log("Simplified ",ruleToSimplify);
            optConditions.push(ruleToSimplify.optSrc2);
            ruleToSimplify.optSrc2 = "";
          }
          // avoid repetitions
          optConditions = _.uniq(optConditions);
          var ruleType = (optConditions.length > 1) ? 'C' : 'A';
          var newRuleC = {type: ruleType, optFather:optRuledByQ9, optChild: optConditions, debugInfo: "regola creata da ENGINE", createdByEngine: "1", simplifyQ9: "1" };
          rules.insert(newRuleC);
          //console.log("Rule added ",newRuleC);
        }
      }
    }


    // TODO: create Q9 rules for Packs that include implicits ruled by Q9 rules


    // rules C default set as first child
    function defaultForCRules(rules){
      //clean all rules different by A,C,E
      rules().filter({"type":{"!is":"A"}},{"type":{"!is":"E"}},{"type":{"!is":"C"}}).remove();
      Log.log("Rules not A,C,E removed");

      //rules C optimization: ordering children in order to have default in first position
      var rulesCToOptimize = rules().filter({"type":"C"}).get();

      for(var k=0; k < rulesCToOptimize.length; k++){
        var ruleC = rulesCToOptimize[k];
        var optChildren = ruleC.optChild;

        // convention -> first child must be the default
        var defaultOptForCRule = null;
        for (var z=0; z < optChildren.length; z++){
          var optID = optChildren[z];
          var optChecked = engine.Opts().getById(optID);
          if (optChecked.selected === "1"){
            defaultOptForCRule = optID;
          }
        }

        if(defaultOptForCRule) {
          var x = $.inArray( defaultOptForCRule, optChildren);
          optChildren.splice(x,1);
          optChildren.splice(0,0,defaultOptForCRule);
          ruleC.optChild = optChildren;
        }

      }
    }


    // setup rule C based upon info inside packs section
    // - setup presetOfSet as first child based on preset = '1'
    // - create a C rules if not found in taffy (rule based upon SET info)
    function setupCRulesForPacks(rules, engine){
      var optPacks = engine.Opts().DB({"pack":"1"}).get();

      for(var k=0; k < optPacks.length; k++){
        var optPack = optPacks[k];

        if (optPack.sets.length > 0){
          for (var z=0; z < optPack.sets.length; z++) {
            var setOfOpts = optPack.sets[z];
            var presetOfSet = null;
            var children = [];
            var optManovra = null;
            for (var y=0; y < setOfOpts.length; y++) {
              var optOfSet = setOfOpts[y];
              // if opt has optmanovra try to find rules with optmanovra as father
              if (optOfSet.manovra && !optManovra){
                optManovra = optOfSet.manovra;
              }
              children.push(optOfSet.id);
              if (optOfSet.preset === '1' && (!presetOfSet)) {
                presetOfSet = optOfSet.id;
              }
            }

            if (optManovra) {
              var ruleToOptimize = rules().filter({type:"C", optFather: optManovra, optChild:{hasAll:children}}).first();
              if(!!ruleToOptimize) {
                ruleToOptimize['manovra'] = "1";
              } else {
                // TODO need improvment
                Log.error("Missing rule: cannot find C rule with optFather=" + optManovra + " and optChild=" + children);
              }
            } else {
              var ruleToOptimize = rules().filter({type:"C", optFather: optPack.id, optChild:{hasAll:children}}).first();
            }

            // if no explicit default no need to reorder children
            if ( presetOfSet ){
              var pos = $.inArray(presetOfSet,children);
              if (pos !== 0 ){
                children.splice(pos, 1);
                children.splice(0, 0, presetOfSet);
              }
            } else {
              var packID = optPack.id;
              var setID = engine.Configuration.data.packs[packID].standalone.sets[z].id
              Log.warn("Missing default in set " + setID + " of pack: " + packID);
            }

            // if there is a rule for the set, setup default as first child
            if (ruleToOptimize) {
              if ( presetOfSet ) {
                ruleToOptimize.optChild = children;
                Log.log("Default modified for ", ruleToOptimize);
              }
              // if no rule is found a new one is created
            } else {
              var newRuleC = {type: "C", optFather: optPack.id, optChild: children, debugInfo: "regola creata da ENGINE", createdByEngine: "1"};
              Log.log("No C rule find for ", optPack.id, children);
              Log.log("Created C rule ", newRuleC);
              rules.insert(newRuleC);
            }

          }
        }

      }
    }

    function orderingRulesCreatedByEngine(rules) {
      var rulesCreatedByEngine = rules().filter({type: "C",createdByEngine:"1" }).get();

      for (var i=0; i < rulesCreatedByEngine.length; i++) {
        var ruleToReorder = rulesCreatedByEngine[i];

        if (ruleToReorder.simplifyQ9 == "1"){
          // extracting rules that contain all the children of rule that need to be ordered
          var similarRules = rules().filter({type: "C", optChild:{hasAll:ruleToReorder.optChild} }).get();

          // applying a fallback chain, try to copy the same order of a "manovra rule" > "INT to mandatory group rule"
          var manovraSimilarRule = _(similarRules).filter({ manovra: "1"}).first();
          var reorderedChildren = [];

          if (!_.isEmpty(manovraSimilarRule)){
            var reorderedChildren = _.intersection(manovraSimilarRule.optChild, ruleToReorder.optChild);
            ruleToReorder.optChild = reorderedChildren;
          } else {
            // fallback
            var intToMandatoryItemsSimilarRule = _(similarRules).filter({ optFather: "INT"}).first();

            if (!_.isEmpty(intToMandatoryItemsSimilarRule)) {
              reorderedChildren = _.intersection(intToMandatoryItemsSimilarRule.optChild, ruleToReorder.optChild);
              ruleToReorder.optChild = reorderedChildren;
            }

          }

        }

      }
    }

    // opt required by root-INT via A rules need to be forced as MUST
    function optEnrichmentWithACERules(rules, engine){
      var rootRulesTypeA = rules().filter({type: "A", optFather: "INT"}).get();
      var optsDestOfRootRulesTypeA = _.pluck(rootRulesTypeA, "optChild");
      for (var i=0, ll=optsDestOfRootRulesTypeA.length; i< ll; i++ ) {
         var optToEnrich = engine.Opts().getById(optsDestOfRootRulesTypeA[i]);
        optToEnrich.must = "1";
       }
    }

    // rules ACE & COLORS optimizations and workarounds
    rulesQ9Simplification.call(this, this.rulesACE, this.rulesCOLOR);
    defaultForCRules.call(this, this.rulesACE);
    setupCRulesForPacks.call(this, this.rulesACE, engine);
    orderingRulesCreatedByEngine.call(this, this.rulesACE);
    optEnrichmentWithACERules.call(this, this.rulesACE, engine);


    // return rules ACE filtered by filter
    this.getRulesACE = function(filter){
      if (filter) {
        return this.rulesACE(filter);
      } else {
        return this.rulesACE();
      }
    };


    // return rules COLOR filtered by filter
    this.getRulesCOLOR = function(filter){
      if (filter){
        return this.rulesCOLOR(filter);
      } else {
        return this.rulesCOLOR();
      }
    };


    // return rules ACE by child
    this.rulesByChild = function (child) {
      var childID;
      if ( typeof child == "string" ) {
        childID = child;
      } else {
        childID = child.id;
      }
      var results = this.getRulesACE({"optChild": {has:childID}}).get();
      return results;
    };


    // return rules ACE by father
    this.rulesByFather = function (father) {
      var fatherID;
      if ( typeof father == "string" ) {
        fatherID = father;
      } else {
        fatherID = father.id;
      }
      var results = this.getRulesACE({"optFather": fatherID}).get();
      return results;
    };

    // return rules ACE by type
    this.rulesByType = function(type) {
      var results = this.getRulesACE({"type": type}).get();
      return results;
    };

    // return rules ACE by type and child
    this.rulesByTypeAndChild = function(type,child) {
      var childID;
      if ( typeof child == "string" ) {
        childID = child;
      } else {
        childID = child.id;
      }
      var results = this.getRulesACE({"type": type, "optChild":{has:childID}}).get();
      return results;
    };

    // return rules ACE by type and father
    this.rulesByTypeAndFather = function(type, father) {
      var fatherID;
      if ( typeof father == "string" ) {
        fatherID = father;
      } else {
        fatherID = father.id;
      }
      var results = this.getRulesACE({"type": type, "optFather": fatherID}).get();
      return results;
    };


    // return rules Color A9 by given OPT Color as Dest
    this.rulesTypeA9ByDest = function(optDest,colDest){
      if (colDest){
        return this.getRulesCOLOR({"type":"A9", "optDest":optDest, "colDest": {has:colDest}}).get();
      } else {
        return this.getRulesCOLOR({"type":"A9", "optDest":optDest}).get();
      }
    };


    // return rules Color E9 by given OPT Color as Dest
    this.rulesTypeE9ByDest = function(optDest){
      return this.getRulesCOLOR({"type":"E9", "optDest":optDest, "colDest": ""}).get();
    };


    // return rules Color Q9 by given OPT as Dest
    this.rulesTypeQ9ByDest = function(optDest){
      return this.getRulesCOLOR({"type":"Q9", "optDest":optDest}).get();
    };

    // return rules Color by OPT src/in of given type
    this.rulesColorBySrc = function(type,optSrc,colSrc){
      if (typeof colSrc === 'undefined'){
        colSrc = "";
      }

      var filter = [
        {optSrc1: optSrc, colSrc1: colSrc},
        {optSrc2: optSrc, colSrc2: colSrc},
        {optSrc3: optSrc, colSrc3: colSrc}
      ];

      return this.getRulesCOLOR(filter).filter({"type":type}).get();
    };


    return this;
  };

})(jQuery);

(function($){
  'use strict';

  var Log = new Logger("EngineRule");
  Log.mute( ! window.LOG_ENGINE );

  // Wrapper for ACE rule object
  var RuleWrapper = window.RuleWrapper = function(engine, ruleObj, statusChange) {

    var obj = $.extend(true, {}, ruleObj);
    this.id = obj.___id;
    delete obj.___id;
    $.extend(true, this, obj);

    this.parsed = false;
    // 0 = deselection, 1 = selection
    this.statusChange = statusChange;

    // Method that implements the logic of the rule
    this.apply = function(ref){
      var optFatherWrapper;
      var optChildWrapper;
      var optChangedByRule;

      if ( ! (optFatherWrapper = engine.queueOPT.getItem(this.optFather)) ){
        optFatherWrapper = new OPTWrapper(engine, this.optFather);
      }

      var children = this.optChild;
      if ( ! $.isArray( children ) ) {
        children = [ children ];
      }

      var args = [];
      for ( var i = 0, child = null, childW = null; child = children[ i++ ]; ) {
        if ( ! (childW = engine.queueOPT.getItem( child )) ){
          childW = new OPTWrapper(engine, child);
        }
        args.push( childW );
      }

      Array.prototype.splice.call( args, 0, 0, ref, optFatherWrapper );
      Log.info("Appling: ", this);
      this[ "apply" + this.type ].apply( this, args );

    };


    // Method that implement the logic of the rule A
    this.applyA = function(ref, optFatherWrapper, optChildWrapper){
      var optChangedByRule = null;

      if (this.statusChange === "1"){

        if ( optChildWrapper.select() ){
          optChangedByRule = optChildWrapper;
        }

      } else {

        if ( optFatherWrapper.deselect() ){
          optChangedByRule = optFatherWrapper;
        }

      }
      return optChangedByRule;
    };


    // Method that implements the logic of the rule E
    this.applyE = function(ref, optFatherWrapper, optChildWrapper){
      var optChangedByRule = null;
      if (this.statusChange === "1"){
        if (optChildWrapper.deselect()){
          optChangedByRule = optChildWrapper;
        }
      }
      return optChangedByRule;
    };


    // Method that implements the logic of the rule C
    this.applyC = function(ref, optFatherWrapper /** , child... */){

      var currentQueue = engine.queueOPT.all();
      var currentQueueLength = currentQueue.length;

      var optChangedByRule = null;
      var children = Array.prototype.slice.call( arguments, 2 );

      var selectedChildren = 0;
      for (var i=0; i < children.length; i++){
        if (children[i].selected === "1"){
          selectedChildren+=1;
        }
      }

      if (selectedChildren < 1 ){
        if (this.statusChange === "1"){
          // At least one child have to be selected
          var j=-1;
          do {
            try {
              j++;
              if (children[j].select()){
                optChangedByRule = children[j];
              }
            } catch(e) {
              Log.warn( children[j].id + "(child of "+ optFatherWrapper.id +") will be skipped, go to the next child" );
              // Remove OPTWrapper from queue
              engine.queueOPT.removeFrom( currentQueueLength );
            }
          } while (!optChangedByRule && children[j+1]);

          if(!optChangedByRule) {
            throw optFatherWrapper.id + " is father of a C rule where no children (" + _.pluck(children,'id') + ") is selected or selectable";
          }

        } else {

          // new management of the C rules (due to MACC-1061)
          try {
            // father deselected only if there is no more children selected
            if (optFatherWrapper.deselect(this.type)) {
              optChangedByRule = optFatherWrapper;
            }
          } catch(e) {
            Log.warn( optFatherWrapper.id + " (father of the C rule) cannot be deselected so I try to select at least one of its children" );

            // queue cleaned from dirty data due to failed father deselection
            engine.queueOPT.removeFrom( currentQueueLength );

            // trying to select at least one of the children
            var j=-1;
            var selectedChild;
            do {
              try {
                j++;
                if (children[j].select()){
                  selectedChild = children[j];
                }
              } catch(e) {
                Log.warn( children[j].id + " (child of "+ optFatherWrapper.id +") will be skipped, go to the next child" );
                // Remove OPTWrapper from queue
                engine.queueOPT.removeFrom( currentQueueLength );
              }
            } while (!selectedChild && children[j+1]);

            // if no child is still selected then throw an exception
            if (!selectedChild){
              throw e + " - no further action is possible!";
            }
          }
        }
      }
      return optChangedByRule;
    };


    return this;
  };






  ////////////////////////////////////////////////////////////////////////////////
  // COLOR RULES MODEL
  ////////////////////////////////////////////////////////////////////////////////


  // Wrapper for Color rule object

  var ColorRuleWrapper = window.ColorRuleWrapper = function(engine, ruleObj) {

    var obj = $.extend(true, {}, ruleObj);
    this.id = obj.___id;
    delete obj.___id;
    $.extend(true, this, obj);

    this.parsed = false;

    // Method that implements the logic of the color rule
    this.apply = function(ref){
      var optDestWrapper;

      if ( ! (optDestWrapper = engine.queueOPT.getItem(this.optDest)) ){
        optDestWrapper = new OPTWrapper(engine, this.optDest);
      }

      for(var j=1; j <= 3; j++){
        var optSrc = this["optSrc"+j];
        var colSrc = this["colSrc"+j];

        if ( optSrc ){
          var optW = engine.Opts().getOptWrapperFromQueueOrTaffy(optSrc);
          var color = optW.selectedColor() ? optW.selectedColor() : "";
          if ( ! (optW.selected === "1" && color === colSrc) ) {
            return false;
            break;
          }
        }
      }

      var args = [ref, optDestWrapper];

      Log.info("Appling: ", this);
      return this[ "apply" + this.type ].apply( this, args );

    };

    // Method that implements the logic of the rule A9
    this.applyA9 = function(ref, optDestWrapper /** colDest is an array **/){

      var optChangedByRule = null;

      // get OPT influenzate da questo cambio
      // ciclo gli OPT
      // Controllo che il colore ATTUALEMNTE selezionato dell'OPT
      //    Sia ancora disponibile
      // se SI
      //     TUTTO OK -> prossimo OPT nel ciclo
      // se NO
      //     seleziono il primo colore disponibile
      //     (Si scatena il metodo setter dell'OPT)
      var currentQueue = engine.queueOPT.all();
      var currentQueueLength = currentQueue.length;

      var colors = this.colDest;
      var pos = $.inArray( optDestWrapper.selectedColor(), colors);
      if ( pos > -1 ) {
        // COLOR OK
      } else {
        var  j= -1;
        do {
          try {
            j++;
            Log.warn(optDestWrapper.id + " is changing color", optDestWrapper.selectedColor(), "->", colors[j] );
            optDestWrapper.selectedColor( colors[j] );
            optChangedByRule = optDestWrapper;
          } catch(e) {
            Log.error(optDestWrapper.id + " cannot change color", optDestWrapper.selectedColor(), "->", colors[j] , ", try next if available." );
            // Cleaning queue
            engine.queueOPT.removeFrom( currentQueueLength );
          }
        } while (!optChangedByRule && colors[j+1]);
      }

      return optChangedByRule;
    };


    this.applyQ9 = function(ref, optDestWrapper){
      // rule Q9 executed from right to left
      // if opt src are all verified it return true
      if (ref === optDestWrapper){
        return true;
      // rule Q9 executed from left to right
      // if opt src are all verified it return opt dest as wrapper
      } else {
        return optDestWrapper;
      }
    };


    // Method that implements the logic of the rule E9
    this.applyE9 = function(ref, optDestWrapper){

      var optInputWrappers = arguments;
      var color = this.colDest; //is a string

      if(color) {
        optDestWrapper.selectColor(color);
      } else {
        optDestWrapper.select();

      }

      return true;
    };

    return this;
  };


})(jQuery);

(function($){
  'use strict';

  var Log = new Logger("OptMngr");
  Log.mute( ! window.LOG_ENGINE );

  var OptManager = window.OptManager = function(engine, data, events){
    this.transaction = null;
    this._optList = TAFFY();
    this._events = events;

    // OPT-must to be initialized since not flagged as must=1 in JSON
    var _defaultsInSetOfMustPack = []; // will be selected = '1'
    var _singlesOfMustPack = []; // will be must = '1'

    // packs stuff added to opt in taffy from json
    var _packStuffInitialization = function(opt,engine){

      // check if OPT is an OPT-Pack and add save pack structure
      var packIDs = Object.keys( engine.Packs() );
      if ( $.inArray(opt.id, packIDs) !== -1 ) {
        opt.pack = "1";
        var pack = engine.Packs()[opt.id];

        // implicits
        opt.implicits = [];
        for (var i=0; i < pack.implicits.length; i++) {
          var implicit = pack.implicits[i];
          opt.implicits.push(implicit.id);
        }

        // standalone
        if (pack.standalone) {
          // standalone with sets
          if (pack.standalone.sets) {
            opt.sets = [];
            for (var i=0; i < pack.standalone.sets.length; i++) {
              var set = pack.standalone.sets[i];
              opt.sets.push(set.list);
              // only for must pack
              if (opt.must === '1') {
                for (var x= 0; x < set.list.length; x++) {
                  var optOfSet = set.list[x];
                  if (optOfSet.preset === '1') {
                    _defaultsInSetOfMustPack.push(optOfSet.id);
                  }
                }
              }
            }
          }

          // TODO: check this
          // standalone without sets
          if (pack.standalone.options) {
            opt.standalone = pack.standalone.options;
            // only for must pack
            if (opt.must === '1') {
              for (var x= 0; x < pack.standalone.options.length; x++) {
                _singlesOfMustPack.push(pack.standalone.options[x].id);
              }
            }
          }
        }

      } else {
        opt.pack = "0";
      }
    }

    _wrap.call(this);

    // inizializza opt list
    $.each(data.options, $.proxy(function(index, opt){

      // using index from json as id for the OPT
      opt.id = index;

      opt.originallySelected = opt.selected;

      // converting colors from array to map with colorcode as keys
      var colorDict = {};
      var color = null;
      $.each(opt.colorList, function(index,colorObj){
        colorDict[colorObj.colIdent] = colorObj;

        // if there is a selected color coming from services then OPT should be marked as selected
        if (colorObj.selected === "1"){
          opt.selected = "1";
          color = colorObj.colIdent;
        }
      });

      opt._originalColorList = opt.colorList;
      opt.colorList = colorDict;

      // if there is no color selected by maserati services the engine choose the first one
      // (but the OPT stay unselected )
      if (!color && !$.isEmptyObject(colorDict) ) {
        color = colorDict[ Object.keys(colorDict)[0] ].colIdent;
      }

      opt._selectedColor = color;

      // check if OPT is a default
      if (opt.originallySelected === "1"){
        opt.preset = true;
        opt.quantity = 1;
      }

      // if packs sections is not empty initialize taffy with packs
      if (engine.Packs()){
        _packStuffInitialization(opt,engine);
      }

      // mandatory initialization based on mandatory section of interfaceConfig json
      if ((opt.group !== "CAL") && (opt.group !== "RIMS") && (opt.group !== "TRIM")) {
        opt.mandatoryGroup = (data.mandatory && $.inArray(opt.group, data.mandatory) ) > -1;
      } else {
        opt.mandatoryGroup = false;
      }

      this._optList.insert( opt );

    }, this));


    // Children of MUST pack initializazions
    // default of Set in Must Pack is considered as originally selected
    for (var j=0; j < _defaultsInSetOfMustPack.length; j++) {
      var defaultInSetOfMustPack = _defaultsInSetOfMustPack[j];
      var optToChange = this.DB({id:defaultInSetOfMustPack}).first();
      optToChange.selected = '1';
      optToChange.originallySelected = '1';
      optToChange.preset = true;
      optToChange.quantity = 1;
    }

    // Single Standalone Opt of Must Pack is must too and cannot be deselected
    for (var j=0; j < _singlesOfMustPack.length; j++) {
      var singleOfMustPack = _singlesOfMustPack[j];
      var optToChange = this.DB({id:singleOfMustPack}).first();
      optToChange.selected = '1';
      optToChange.originallySelected = '1';
      optToChange.preset = true;
      optToChange.must = '1';
      optToChange.quantity = 1;
    }


    // setup listener for TaffyDB on UpdateonEvent
    var dbSettings = {
      onUpdate: $.proxy(this._onUpdate, this)
    };

    this._optList.settings( dbSettings );


    this.Engine = function(){
      return engine;
    };

    this.rollbackTransaction = function(){
      if ( !this.hasTransaction() ) {
        return false;
      }
      this._setData( this.transaction );
      this.transaction = null;
      return true;
    };

    this.resetCache = function(){
      this.__cache__ = {};
    };

    this._getData = function(){
      return this.DB().get();
    };

    this._setData = function(data) {
      // reset cache
      this.resetCache();
      this._optList = TAFFY( data );
      this._optList.settings(dbSettings);
      return this;
    }

  };



  OptManager.prototype = {

    _onUpdate: function(obj, newdata){
      var o = $.extend(true, {}, obj, newdata);
      this.__cache__[ o.id ] = o;
      this._events && this._events.onUpdate && this._events.onUpdate(o);
    },

    // return an OPT of a given ID as an OPTWrapper
    getOpt: function(id, skipThrow) {
      try {
        return new OPTWrapper(this.Engine(), typeof(id) == "string" ? (this.__cache__[ id ] || id) : id );
      } catch( e ) {
        if ( skipThrow ) return null;
        throw e;
      }

    },

    // return an OPT of a given ID as a simple taffy object
    getById: function(id) {
      return this.__cache__[ id ] || (this.__cache__[ id ] = this.DB({id: id}).first() );
    },

    // update a record with a given ID with new data
    update: function(id, obj) {
      return this.DB({"id":id}).update(obj);
    },


    startTransaction: function(){
      if ( this.transaction ) {
        throw "Transaction already exists, commit or rollback before starting another one";
      }
      this.transaction = this.DB().get();
      return true;
    },

    confirmTransaction: function(){
      this.transaction = null;
      return true;
    },

    // method to get all OPT of a given group from taffy
    // all opt returned are in wrappers
    getOptWrapperByGroup: function(group) {
      var result = [];
      var tmp = this.DB({group: group}).get();

      for ( var i = 0, l = tmp.length; i < l; i++ ) {
        result.push( new OPTWrapper( this.Engine(), tmp[i] ) );
      }

      return result;
    },


    // method to get all OPT-Pack with status selected = '1' from queue or taffy
    getSelectedPacksFromQueueOrTaffy: function(){
      var optPackFromTaffy = this.DB({pack:"1", selected:"1"}).get();
      var selectedOptPackWrappers = [];

      for (var z=0; z < optPackFromTaffy.length; z++) {
        var optPackWrapperFromTaffy = this.getOpt(optPackFromTaffy[z]);
        selectedOptPackWrappers.push(optPackWrapperFromTaffy);
      }

      var optWrappersFromQueue = this.Engine().queueOPT.all();
      for (var i = 0; i < optWrappersFromQueue.length; i++ ) {
        var optWrapperFromQueue = optWrappersFromQueue[i];
        if (optWrapperFromQueue.isSelected() && optWrapperFromQueue.isPack()) {
          selectedOptPackWrappers.push(optWrapperFromQueue);
        }
      }
      return selectedOptPackWrappers;
    },


    // method to get all OPT of a given group from queue
    // all opt returned are in wrappers
    getOptWrapperByGroupFromQueue: function(g){
      var all = this.Engine().queueOPT.all, result = [];
      for ( var i = 0, l = all.length; i < l; i++ ) {
        if ( all[i].group == g ) {
          result.push( all[i] );
        }
      }
      return result;
    },


    // method to get an OPT of a given ID searching in taffy and in queue
    // opt is returned as an OptWrapper
    getOptWrapperFromQueueOrTaffy: function(id){
      var optWrapper = null;

      if (! (optWrapper = this.Engine().queueOPT.getItem(id)) ){
        optWrapper = new OPTWrapper(this.Engine(), this.__cache__[id] || id);
      }

      return optWrapper;
    },


    // method to get all OPT  of a given group both from taffy and queue
    // all opt returned are in wrappers
    getAllOptWrapperByGroup: function(g) {
      var t = this.getOptWrapperByGroup(g);
      var q = this.getOptWrapperByGroupFromQueue(g);
      var result = [];
      for ( var i = 0, l = t.length; i < l; i++ ) {
        var found = false;
        var tO = t[i];
        for ( var j = 0, z = q.length; j < z; j++ ) {
          var qO = q[j];
          if ( tO.id == qO.id ) {
            found = true;
            result.push( qO );
            break;
          }
        }
        if ( !found ) {
          result.push( tO );
        }
      }
      return result;
    }

  };



  function _wrap() {

    this.__cache__ = {};

    this.DB = function(args){
      if ( arguments.length > 0 ) {
        return this._optList.apply( this._optList, arguments );
      } else {
        return this._optList();
      }
    };


    function checkFilter(opt, filters) {
      for ( var fil in filters ) {
        var filter_value = filters[ fil ];
        var condition_value = opt[ fil ] == filter_value;
        if ( ! condition_value ) {
          // skip this opt
          return false;
        }
      }
      return true;
    }

    this.DBSimulated = function(args){

      var result_db = this._optList.apply( this._optList, arguments ).get();
      var result_cache = this.CACHE( {} );
      for ( var i = 0, l = result_db.length; i < l; i++ ) {
        var opt_db = result_db[ i ], found = false;
        for ( var j = 0, z = result_cache.length; j < z; j++ ) {
          var opt_cache = result_cache[ j ];
          if ( opt_cache.id == opt_db.id ) {
            found = true;
            break;
          }
        }
        if ( ! found ) {
          result_cache.push( opt_db );
        }
      }

      for ( var i = result_cache.length - 1; i >= 0; i-- ) {
        var opt = result_cache[i];
        !checkFilter(opt, args) && result_cache.splice(i, 1);
      }
      return result_cache;
    };

    this.CACHE = function(filters){
      var result = [];
      var opts = this.Engine().queueOPT.all();
      for ( var i = 0, l = opts.length; i < l; i++ ) {
        var opt = opts[ i ], to_add = true;
        var to_add = checkFilter(opt, filters);
        if ( to_add ){
          result.push( opt.__db_data );
        }
      }

      return result;
    };

    this.hasTransaction = function(){
      return !!this.transaction;
    };

  }

})(jQuery);

(function($){
  'use strict';

  var Log = new Logger("OptModel");
  Log.mute( ! window.LOG_ENGINE );

  // Wrapper for OPT object retrieved from TAFFY Instance
  var OPTWrapper = window.OPTWrapper = function(engine, optID) {

    var optObj = null;
    if ( typeof optID == "string" ) {
      optObj = engine.Opts().getById(optID);
    } else if ( optID.___id ) {
      optObj = optID;
    }

    if ( !optObj ) {
      throw "no OPT found for code: " + optID;
    }

    _wrap.call(this);

    // setup of colors properties
    var colors = [];
    var color = null;

    var t = Object.keys(optObj.colorList);

    for (var i=0; i < t.length; i++ ){

      var ident = t[i];

      var cc = optObj.colorList[ident];

      colors.push(cc.colIdent);
      if (cc.selected === "1"){
        color = cc.colIdent;
      }
    }
    // end setup of colors properties
    this.__db_data = $.extend({}, optObj);
    $.extend(true, this, optObj);
    this.parsed = false;
    Log.log("Wrapped OPT", optID);


    // Methods to manage change of quantity
    this.updateQuantity = function(quantity) {
      var updated = false;
      //update quantity only if OPT is allowMultiple = '1'
      if(this.isAllowingMultiple() ) {
        this.__db_data.quantity = this.quantity = quantity;
        updated = true;
      } else {
        Log.error("Cannot update quantity for ", this.id);
      }

      return updated;
    };

    this.getQuantity = function() {
      return this.quantity;
    }

    // Method to manage change of status
    this.changeStatus = function(statusLevel, statusValue, ruleType){

      if (this[statusLevel] != statusValue){  //  aggiungere condizione su parsing status
        if (this.unparsed()) {

          this.parsed = true;

          if ( statusValue === "0" ) {
            // Deselecting OPT
            var selectedOpt = null;


            // Pack cannot be deselected by its children
            // It works also for pack that are must
            if (ruleType && ruleType === "C" && this.isPack()) {
              Log.info("OPT Pack cannot be deselected by its children: " + this.id +" trying to restart chain");
              var rules = this.extractRules();
              var forcingStimulation = true;
              this.applyRules( rules, forcingStimulation);
              return true;
            }


            // Opt required by color configuration cannot be deselected
            if (this.requiredByColorConfig()){
              Log.info("OPT cannot be deselected since it's required by color config(E9 rules): " + this.id +" trying to restart chain");
              var rules = this.extractRules();
              var forcingStimulation = true;
              this.applyRules( rules, forcingStimulation);
              return true;
            }

            // OPT Commercial/Tech Must (not in a mandatory group) cannot change status
            // When a child of a C rules tries to deselect its MUST father a re-stimulaton occurs
            if ( this.isMust() && !this.isInMandatoryGroup() ) {

              if ((ruleType && ruleType === "C") || this.id === "INT") { // fix MACC-998
                Log.info("OPT MUST cannot be deselected by its children : " + this.id + "trying to restart chain");
                var rules = this.extractRules();
                var forcingStimulation = true;
                this.applyRules( rules, forcingStimulation);
                return true;
              } else {
                Log.error("OPT MUST cannot be deselected: " + this.id);
                throw this.id + " cannot be deselected because is a Commercial/Tech MUST and it does not own to a mandatory group";
              }

            }


            // TODO rimuovere perche' con la nuova gestione impliciti non serve piu'
            // a pack deselected can contain implicits, if so need to recheck mandatory
            // re-stimulating INT OPT forcing a deselection > selection
            if (this.isPack() && this.implicits.length > 0){
              var optINT = engine.Opts().getOpt("INT");
              optINT.deselect();
            }


            // a mandatory group cannot be empty
            if ( this.isInMandatoryGroup() ) {
              // Mandatory group found
              var opts = engine.Opts().getAllOptWrapperByGroup( this.group );
              for( var i = 0, l = opts.length; i < l; i++ ) {
                var o = opts[ i ];
                if ( o.id == this.id ) {

                } else if ( engine.Opts().getOptWrapperFromQueueOrTaffy(o.id).isSelected() ) {

                  selectedOpt = o;

                  break;
                }
              }

              if ( selectedOpt && !this.isMust() ) {
                // Another OPT is selected in this group, we can continue without error

              } else {
                // No other OPT is selected in this group, we should select the default OPT
                for( var i = 0, l = opts.length; i < l; i++ ) {
                  var o = opts[ i ];
                  if ( o.isDefault() ){
                    if ( o.id == this.id ) {
                      // this OPT is default of mandatory group and cannot be deselected by its children
                      // I need to simulate a statusChange in order to launch a chain of rules
                      // in the opposite direction (from this OPT to its children) in order to select a child
                      // when another one is deselected

                      Log.info("OPT is the default opt for this mandatory group: " + o.id +" trying to restart chain");
                      var rules = this.extractRules();
                      var forcingStimulation = true;
                      this.applyRules( rules, forcingStimulation);
                      return true;

                    } else {
                      selectedOpt = o;
                      o.select();
                      break;
                    }
                  }
                }

                if ( ! selectedOpt ) {
                  throw "No default OPT has been found";
                }

              }

            }

          }

          // cannot select an OPT ruled by Q9 when its Q9 are not verified
          if(statusValue === "1" && !( this.isSelectable() ) ){
            throw this.id + " is not selectable due to Q9 rules";
          }

          this.__db_data[statusLevel] = this[statusLevel] = statusValue;
          this.__db_data.quantity = this.quantity = (statusValue === '1') ? 1 : 0;



          var rules = this.extractRules();
          this.applyRules( rules );
          var action = statusValue === '1' ? "selected" : "deselected";
          Log.info("Status changed for: ", this.id, action);
          return true;
        } else {
          Log.error("Status conflict for: ", this.id, this.label);
          throw this.id + " cannot change its status: " + this[statusLevel];
        }
      }
      return false;
    };


    // check if OPT is selectable based on results of Q9 rules
    this.isSelectable = function(){
      var rules = engine.Rules().rulesTypeQ9ByDest(this.id);
      var l = rules.length;
      if (l > 0) {
        for(var i=0; i < l; i++){
          var ruleWrapper = new ColorRuleWrapper(engine, rules[i]);
          // if a Q9 rule is verified than OPT is selectable
          if (ruleWrapper.apply(this)){
            return true;
          }
        }
      } else {
        // if no Q9 rules found is always selectable
        return true;
      }

      for (var z=0, rulesSize = rules.length; z < rulesSize ; z++ ){
        Log.warn("OPT " + this.id + " not selectable due to Q9 rule not verified: ", rules[z]);
      }

      return false;
    };


    // extractRules ACE from taffy instance
    // on selection rules are filtered by father
    // on deselection rules are filtered by child
    this.extractRules = function(){
      var extractedRules = {
        "ace": [],
        "colors": []
      };

      if (this.selected === "1") {
        extractedRules["colors"] = extractedRules["colors"].concat(engine.Rules().rulesColorBySrc("A9",this.id, ""));
        extractedRules["colors"] = extractedRules["colors"].concat(engine.Rules().rulesColorBySrc("E9",this.id, ""));
        extractedRules["ace"] = extractedRules["ace"].concat(engine.Rules().rulesByTypeAndFather("A",this));
        extractedRules["ace"] = extractedRules["ace"].concat(engine.Rules().rulesByTypeAndFather("C",this));
        extractedRules["ace"] = extractedRules["ace"].concat(engine.Rules().rulesByTypeAndFather("E",this));

      } else if (this.selected === "0") {

        // if selected OPT is src in A9 rules the engine must check if the selected color of the optDest of these rules
        // is still valid or not
        var optsWithColourTouchedByDeselection = _(engine.Rules().rulesColorBySrc("A9",this.id, "")).pluck("optDest").uniq();

        for (var j= 0; j < optsWithColourTouchedByDeselection.length; j++) {
          var optW = engine.Opts().getOpt(optsWithColourTouchedByDeselection[j]);
          optW.changeAvailableColors(optW.getAvailableColors());

        }

        extractedRules["ace"] = extractedRules["ace"].concat(engine.Rules().rulesByTypeAndChild("A",this));

        var extractedCRules = engine.Rules().rulesByTypeAndChild("C",this);
        var extractedCRulesOrdered = [];

        // Workaround for opt-manovra: rules C with opt-manovra as father must be executed after packs rules and before all others MACC-1049
        for (var i=0; i < extractedCRules.length; i++) {
          var ruleC = extractedCRules[i];
          if (ruleC.manovra === "1") {
            extractedCRulesOrdered.splice(0,0,ruleC);
          } else {
            extractedCRulesOrdered.push(ruleC);
          }
        }

        // Workaround for packs: rules C with pack as father must be executed before all
        for (var i=0; i < extractedCRules.length; i++) {
          var ruleC = extractedCRules[i];
          var optFatherWrapper = engine.Opts().getOpt(ruleC.optFather);
          if (optFatherWrapper.isPack()) {
            extractedCRulesOrdered.splice(0,0,ruleC);
          }
        }
        // End workarounds

        extractedRules["ace"] = extractedRules["ace"].concat(extractedCRulesOrdered);
      }

      return extractedRules;
    };


    // rules extracted parsed and executed
    this.applyRules = function(rules,forcingStimulation){

      var action = this.selected === '1' ? "selected" : "deselected";
      Log.info("Trying status change for: ", this.id, this.label, action);

      if (!(forcingStimulation)) {
        engine.queueOPT.insert(this);
      }

      if (rules["colors"].length > 0 ) {
        this.applyColorRules(rules);
      }

      for(var i=0; i < rules["ace"].length; i++){
        var ruleWrapper = new RuleWrapper(engine, rules["ace"][i], this.selected);
        ruleWrapper.apply(this);
      }

    };


    // Selection method
    this.select = function() {
      return this.changeStatus("selected","1")
    };


    // deselection method
    this.deselect = function(ruleType) {
      return this.changeStatus("selected","0",ruleType)
    };

    this.getColors = function(){
      // var colors = [];

      // $.each(this.colorList, function(k, c){
      //   colors.push( c );
      // });
      // return colors;
      return this._originalColorList;
    };


    // calculate available colors based on actual configuration and A9 rules
    this.getAvailableColors = function(){
      var availableColors = []
      var rules = engine.Rules().rulesTypeA9ByDest(this.id);
      var l = rules.length;
      if (l > 0){
        for(var i=0; i < l; i++){
          var rule = rules[i];
          var ok = true;
          for(var j=1; j <= 3; j++){
            var optSrc = rule["optSrc"+j];
            var colSrc = rule["colSrc"+j];

            if (optSrc) {
              var optW = engine.Opts().getOptWrapperFromQueueOrTaffy(optSrc);
              var color = optW.selectedColor();

              // new A9 implementation: colSrc is optional
              if (!(optW.selected == "1" && (color == colSrc || _.isEmpty(colSrc) ) )) {
                ok = false;
                break;
              }
            }
          }

          if ( ok ) {
            availableColors = availableColors.concat(rule.colDest);
          }
        }

        // new A9 behaviour (MACC-1116): if no rule is verified then all colours are available
        if (_.isEmpty(availableColors)) {
          availableColors.push.apply(availableColors, Object.keys(this.colorList));
        }

      } else {
        //if no rule found all colors are available (for example INT and EXT)
        availableColors.push.apply(availableColors, Object.keys(this.colorList));
      }

      var avail_colors = [], unique = _.uniq(availableColors);

      // we want to respect the order of the colorList as per MACC-994
      for( var i = 0, l = this._originalColorList.length; i < l; i++ ) {
        var c = this._originalColorList[i], colId = c.colIdent;
        if ( $.inArray( colId, unique ) > -1 ) {
          avail_colors.push( colId );
        }
      }


      return avail_colors;
    };


    // change range colors method
    this.changeAvailableColors = function(colors) {

      //if (!_.isEmpty(colors)) {
        if ($.inArray(this.selectedColor(), colors) === -1) {
          this.selectColor(colors[0]);
          return true;
        }
        return false;

      //} else {
      //  this.deselect();
      //  return true;
      //}
    };


    // select specified color method
    this.selectColor = function(color){

      var availableColors = this.getAvailableColors();

      if ( $.inArray( color, availableColors ) !== -1){
        // setter
        return this.selectedColor( color );
      }
      throw color + " is no selectable for " + this.id + " -> not in avalable colors " + availableColors;
    };


    // extract color rules
    this.extractColorRules = function(color){
      var extracted_rules = {
        "ace": [],
        "colors": []
      };
      extracted_rules["colors"].push.apply(extracted_rules["colors"],engine.Rules().rulesColorBySrc("A9",this.id, color));
      extracted_rules["colors"].push.apply(extracted_rules["colors"],engine.Rules().rulesColorBySrc("E9",this.id, color));
      return extracted_rules;
    };


    // apply color rules
    this.applyColorRules = function(rules){

      engine.queueOPT.insert(this);

      for( var i = 0, l = rules["colors"].length; i < l; i++ ){
        var ruleW = new ColorRuleWrapper( engine, rules["colors"][ i ] );
        ruleW.apply(this);

      }
    };



    // check if an OPT is required by a color-configuration
    // this check help changeStatus to decide if an opt is deselectable
    this.requiredByColorConfig = function(){
      var rulesE9 = engine.Rules().rulesTypeE9ByDest(this.id);
      var required = false;
      for(var i=0; i < rulesE9.length; i++){
        var rule = rulesE9[i];
        var ok = true;
        for(var j=1; j <= 3; j++){
          var optSrc = rule["optSrc"+j];
          var colSrc = rule["colSrc"+j];
          if ( optSrc ){
            var optW = engine.Opts().getOptWrapperFromQueueOrTaffy(optSrc);
            var color = optW.selectedColor() ? optW.selectedColor() : "";
            if ( ! (optW.selected === "1" && color === colSrc) ) {
              ok = false;
              break;
            }
          }
        }
        if (ok) {
          required = true;
          break;
        }
      }
      return required;
    };


    // check if a status-change or color-change of this OPT
    // modify availabilty of other OPTs (via Q9 rules Src > Dest)
    this.calculateAfterEffects = function(oldValue) {

      var rulesForOldValue = engine.Rules().rulesColorBySrc("Q9", this.id, oldValue);

      // extract all opt selected and verified by old value
      for (var i=0; i < rulesForOldValue.length; i++){
        var ruleWrapperForOldValue = new ColorRuleWrapper(engine, rulesForOldValue[ i ] );
        var optWrapperForOldValue = null;
        if ( ! (optWrapperForOldValue = engine.queueOPT.getItem(ruleWrapperForOldValue.optDest)) ){
          optWrapperForOldValue = new OPTWrapper(engine, ruleWrapperForOldValue.optDest);
        }

        if (optWrapperForOldValue.selected === '1'){
          if (  ! ( optWrapperForOldValue.isSelectable() )  ){
            Log.info("Q9 rules no more verified ", optWrapperForOldValue.id , "will be deselected!")
            optWrapperForOldValue.deselect();
          }
        }
      }

    };


    this.Engine = function() {
      return engine;
    };


    return this;
  };


  function _wrap() {

    this.unparsed = function() {
      return !this.parsed;
    };

    this.isDefault = function() {
      return this.preset;
    };

    this.isPack = function() {
      return this.pack === '1' ? true : false;
    };

    this.isMust = function() {
      return this.must === '1' ? true : false;
    };

    this.isHidden = function() {
      return this.hidden === '1' ? true : false;
    };

    this.isAllowingMultiple = function(){
      return this.allowMultiple === '1' ? true: false;
    };

    this.isSelected = function() {
      return this.selected === '1' ? true : false;
    };

    this.isInMandatoryGroup = function() {
      return this.mandatoryGroup;
    };

    this.isOnlyImplicit = function() {
      return this.onlyImplicit === '1' ? true : false;
    };


    this.selectedColor = function(color){
      if ( arguments.length <= 0 ) {
        return this._selectedColor;
      }

      var touched = false;

      // color selection imply opt selection
      if (this.selected !== '1'){
        this.select();
        touched = true;
      }

      // calculate rules only if selectedColor changed from previous color
      if ( this.selectedColor() !== color ){
        var oldSelectedColor = this.selectedColor();
        this.__db_data._selectedColor = this._selectedColor = color;
        this.applyColorRules( this.extractColorRules( color ) );
        this.calculateAfterEffects(oldSelectedColor);

        touched = true;
      }

      return touched;
    };

  }



})(jQuery);

(function($) {
  'use strict';

  var Log = new Logger("EngineCore");
  Log.mute( ! window.LOG_ENGINE );

  //var JsonURL = "json/json-model-custom-7578000-china.json"

  var Engine = window.Engine = function(config) {
    this.queueOPT = new Queue(this);
    this.uuid = new Date().getTime();

    Log.info("new engine", this.uuid);

    _wrap.call(this);

    return this;
  };

  Engine.prototype = {

    initEvent: function(){
      return this;
    },

    _config: {
      rules: null, opts: null, packs: null, data: null
    },


    // constructor of the engine. here we initialize all dta structure for a new scenario:
    // - queues flushed
    // - optManager: init with data from json and callback on update
    // - ruleManager: init with data from json
    initData: function(config, callback){

      function load(data) {

        this.initScenario();

        // original data from json used to initialize taffy db in OptManager
        if (data) {
          this.Configuration.data = data;
        }

        // packs data
        if (data.packs) {
          this.Configuration.packs = data.packs;
        }

        this.Configuration.opts = new OptManager(this, data, {
          // callback to update changes into real CONFIG
          onUpdate: $.proxy(function (opt){
            $(this).trigger("updated", opt );
          }, this)
        });
        Log.log("OPT loaded");

        this.Configuration.rules = new RuleManager(this, data);
        Log.log("Rules loaded");

        // carico dati nella GUI al primo avvio
        callback && callback.call(this, data);
        $(this).trigger("_loaded", [ this.Opts().DB().get() ] );
      }

      config = load.call(this, config);
    },


    // scenario initialization: queue flushed
    initScenario: function(){
      if ( ! this.queueOPT.isEmpty() ){
        this.queueOPT.flush();
      }

      Log.log("Scenario initialized: rules & opt queue reset");
    },


    // method exposed to GUI in order to check which colors are available for a given OPT
    // check follow A9 rules for current configuration (selected colors)
    availableColorsByOPT: function(idOPT){

      var optWrapper = null;

      try {
        optWrapper = this.Opts().getOpt(idOPT);
      } catch(e) {
        Log.error(e);
        throw e
      }

      return optWrapper.getAvailableColors();
    },


    // method exposed to GUI to check if OPT is selecteable based on Q9 Rules
    checkSelectable: function(idOPT){
       var optWrapper = null;

       try {
           optWrapper = this.Opts().getOpt(idOPT);
       } catch(e) {
           Log.error(e);
           throw e
       }

       return optWrapper.isSelectable()  ;
    },

    // method exposed to GUI in order to select a new color for an OPT with colors
    // it start a chain of rules than can modify OPT with colors and not only
    updateColor: function(idOPT, idColor, force){

      var mode = force ? "PERSISTENT MODE" : "SIMULATION MODE";
      Log.info("["+mode+"] GUI > ENGINE " + idOPT + " new color " + idColor + " via click");

      this.initScenario();

      var clickedOptWrapper = null, touched = false;
      try {
        clickedOptWrapper = this.Opts().getOpt(idOPT);
      } catch(e) {
        Log.error(e);
      }

      if ( ! clickedOptWrapper ) {
        return false;
      }

      try {
        touched = clickedOptWrapper.selectColor(idColor);
      } catch(e) {
        Log.warn( e );
        Log.error( "Error with color", idOPT, idColor );
        $(this).trigger("errorColor", [clickedOptWrapper, idColor, e] );
        return force = false;
      }


      /**
       * In case of mandatory group:
       * ClickecOptWrapper could not be the first OPT in the queue as per our expectation,
       * because of "mandatory group" management (see OptModel#changeStatus).
       * This code will restore the natural conditions in the queueOPT just before firing events
       */
      var results = this.queueOPT.all().slice(0);
      var pos = this.queueOPT.indexOf(clickedOptWrapper);
      if ( pos > 0 ) {
        // ClickedOptWrapper is not the first OPT in queue. we should remove it from its actual position
        // and add it as first element in the array to be fired with events
        results.splice(pos, 1);
        results.splice(0, 0, clickedOptWrapper);
      }
      results = results.slice(1);


      if ( force && touched ){
        // color change in real config
        $.each(this.queueOPT.all(), $.proxy(function(i, optWrapper){
          var statusLevel = "selected"; // temporary hardcored
          var obj = {};
          obj[ statusLevel ] = optWrapper[statusLevel];
          obj._selectedColor = optWrapper.selectedColor();

          this.Opts().update( optWrapper.id, obj );

          var actionOnOPT = optWrapper[statusLevel] === '1' ? "ADDED" : "REMOVED"
          var colorCode = (typeof optWrapper.selectedColor() !== "undefined") ? optWrapper.selectedColor() : "";
          Log.info("OPT changed status: ("+actionOnOPT+") ", optWrapper.id, colorCode);
        }, this));

        $(this).trigger("_processed", [clickedOptWrapper, results, idColor]);

      } else if ( !force ) {

        Log.warn("!!! Simulation has been finished !!! Changes are: ", _.pluck(this.queueOPT.all(), 'id') );
        // console.info("PREZZO", this.calculateTotalCarPrice() );
        !this._events_disabled && $(this).trigger("simulatedColor", [clickedOptWrapper, results, this.calculateTotalCarPrice()] );


      }
      // var queueResults = this.queueOPT.all().slice(0);
      !this._events_disabled && this.initScenario();
      return [clickedOptWrapper].concat(results);
    },



    // method exposed to GUI in order to update OPT quantity (for example for accessories)
    updateQuantity: function(idOPT,quantity) {
      Log.info("Requested quantity update for", idOPT, quantity);

      var clickedOptWrapper = null;
      try {
        clickedOptWrapper = this.Opts().getOpt(idOPT);
      } catch(e) {
        Log.error(e);
      }

      if (clickedOptWrapper && clickedOptWrapper.updateQuantity(quantity)) {
        var obj = {};
        obj.quantity = quantity
        obj.selected = quantity > 0 ? "1" : "0";
        this.Opts().update( clickedOptWrapper.id, obj );
        Log.info("Engine > GUI OPT quantity updated", idOPT, quantity);
      } else {
        return false;
      }

      $(this).trigger("_processed", [ clickedOptWrapper, [] ]);
      this.initScenario();
      return [clickedOptWrapper]
    },



    // method exposed to GUI in order to select or deselect an OPT
    // it start a chain of rules than can modify others OPT
    update: function(idOPT, statusLevel, status, force){
      var mode = force ? "PERSISTENT MODE" : "SIMULATION MODE";
      var action = (status === "1") ? "select" : "deselect";
      Log.info("["+mode+"] GUI > ENGINE " + idOPT + " " + action + " via click");
      this.initScenario();

      var clickedOptWrapper = null;
      try {
        clickedOptWrapper = this.Opts().getOpt(idOPT);
      } catch(e) {
        Log.error(e);
      }

      if ( ! clickedOptWrapper ) {
        return false;
      }

      try {
        clickedOptWrapper[ action ]();
      } catch(e) {
        // here can be placed the mail sender for errors
        Log.error(e);
        Log.warn( "Error while appling rules", "" + e);
        $(this).trigger("error", [clickedOptWrapper, e] );
        return force = false;
      }


      /**
       * In case of mandatory group:
       * ClickecOptWrapper could not be the first OPT in the queue as per our expectation,
       * because of "mandatory group" management (see OptModel#changeStatus).
       * This code will restore the natural conditions in the queueOPT just before firing events
       */
      var results = this.queueOPT.all().slice(0);
      var pos = this.queueOPT.indexOf(clickedOptWrapper);
      if ( pos > 0 ) {
        // ClickedOptWrapper is not the first OPT in queue. we should remove it from its actual position
        // and add it as first element in the array to be fired with events
        results.splice(pos, 1);
        results.splice(0, 0, clickedOptWrapper);
      }
      results = results.slice(1);



      // if force is true is a simulation
      // if force is false
      if ( force ) {
        Log.log("Save applied rules");
        // each opt parsed in the queue is updated to REAL config
        $.each(this.queueOPT.all(), $.proxy(function(i,optWrapper){
          var obj = {};
          obj.selected = optWrapper.selected;
          obj.quantity = optWrapper.quantity;

          var colorCode = (typeof optWrapper.selectedColor() !== "undefined") ? optWrapper.selectedColor() : "";

          if (colorCode) {
            obj._selectedColor = colorCode;
          }

          this.Opts().update( optWrapper.id, obj );

          var actionOnOPT = optWrapper.selected === '1' ? "ADDED" : "REMOVED"
          Log.info("OPT changed status: ("+actionOnOPT+"): ", optWrapper.id, colorCode);
        }, this));

        $(this).trigger("_processed", [clickedOptWrapper, results]);

      } else {
        Log.warn("!!! Simulation has been finished !!! Changes are: ", _.pluck(this.queueOPT.all(), 'id') );
        // console.info("PREZZO",  );
        !this._events_disabled && $(this).trigger("simulated", [clickedOptWrapper, results, this.calculateTotalCarPrice()] );
      }

      !this._events_disabled && this.initScenario();
      Log.info("Engine jobs completed!");
      return [clickedOptWrapper].concat(results);
    },

    beginTransaction: function(){
      if ( this.Opts().startTransaction() ) {
        $(this).trigger("transactionStart");
        return true;
      }
    },
    commitTransaction: function() {
      if ( this.Opts().confirmTransaction() ) {
        $(this).trigger("transactionConfirmed");
        return true;
      }
    },
    rollbackTransaction: function(){
      if ( this.Opts().rollbackTransaction() ) {
        $(this).trigger("transactionRollback", [ this.Opts().DB().get() ] );
        return true;
      }
    },
    hasTransaction: function(){
      return this.Opts().hasTransaction();
    },


    /**
     * Used in case of Recommendation
     *  disable events for simulation
     */
    disableEvents: function() {
      this._events_disabled = true;

      // we should reset the queueOpt
      this.initScenario();
    },

    enableEvents: function(){
      this._events_disabled = false;

      // we should reset the queueOpt
      this.initScenario();
    }

  };



  function _wrap() {

    this.Configuration = this._config;

    this.Opts = function() {
      return this.Configuration.opts;
    };

    this.Rules = function() {
      return this.Configuration.rules;
    };

    this.Packs = function() {
      return this.Configuration.packs;
    };

    this.initEvent();

  }


})(jQuery);

/***
 - queueSelectedOPTWrappers

 - listSelectedOPTPackIDs

 - baseCarPrice

 - totalCarPrice

 + Init() / Costrutture():
 1- inizializzo queueSelectedOPTWrappers uno storage/queue di OPTWrappers con stato
 di selected a 1, indipendentemente che siano OPT o OPT-Pask
 2- inizializzo listSelectedOPTPackIDs, un array con gli id degli OPT-pack prelevati
 dallo storage del punto 1
 3- inizializzo prezzo base vettura




 + caculateOptPriceByID(id):
 calcolo prezzo opt con id specificato
 1- se l'id specifica un OPT normale ritorno semplicemente il prezzo

 2- se l'id specifica un OPT-pack mi aspetto una ricorsione e il prezzo ritornato sara' la
 somma del prezzo dell'opt-pack piu' i prezzi degli OPT standalone "i" a patto che non risultino
 gia' "parsed" poiche' inclusi e gia' calcolati per altri pack commerciali




 + calculatePackBasePrice(id):
 calcolo prezzo base del pack commerciale indentificato dall'opt pack con id pari a id passato
 come parametro

 Prezzo base = prezzo opt-pack + prezzo opt standalone "i" default




 + calculateTotalCarPrice()
 esegue il calcolo del prezzo della vettura e lo salva dentro totcalCarPrice (o lo restituisce direttamente al chiamante)

 1- ciclo sui listSelectedOPTPackIDs ed eseguo calcolo prezzi e li sommo in totalCarPrice
 2- ciclo su queueSelectedOPTWrappers per vedere quali opt sono rimasti "unparsed" e sommo i loro prezzi in totalCarPrice





 NOTE
 - se un OPT o un OPT-pack e' un tassativo commerciale non devo considerarlo nel prezzo
 - se un OPT e' un implicito non devo considerarlo nel prezzo
 - se un OPT e' uno standalone flaggato come 'i' devo considerarlo nel calcolo del prezzo
 - se un OPT e' uno standalone flaggato come 'e' non devo considerarlo nel calcolo del prezzo
 - se un OPT e' un OPT-Pack deve essere conteggiato nel calcolo del prezzo solo nel pack commerciale in cui si trova come padre
 - quando considero un OPT (che non sia un OPT-Pack) per il calcolo del prezzo lo flaggo come "parsed" in modo da non
 considerarlo due o + volte nel computo del prezzo totale
 - se un OPT e' standalone esterno ovvero si trova in configurazione senza essere specificato in un pack attivo devono considerarlo

 quindi

 PREZZO TOTALE =
 prezzo base vettura +
 prezzi opt-pack +
 prezzi opt standalone flaggati come "i" (considerati una sola volta se sono comuni a + pack) +
 prezzi opt standalone esterni


 ***/

(function ($) {
  'use strict';

  var Log = new Logger("PriceCalc");
  Log.mute( ! window.LOG_ENGINE );

  window.optsParsedForDynamicPackPrice = [];

  /**
   * Price helper to calculate price of single OPT, second param is optional, is for EXT only and indicates colorCode
   * @param opt
   * @param colorCode (optional)
   * @returns {number}
   */
  Engine.prototype.calculateOptPrice = function (opt, colorCode) {
    var optWrapper;
    if (typeof opt === "string") {
      optWrapper = this.Opts().getOpt(opt);
    } else {
      optWrapper = opt;
    }

    var price = 0;

    // if colorCode is nut but the opt is and opt with color selected color must be considered as colorCode
    colorCode = colorCode || optWrapper.selectedColor() || null;

    // all color OPT
    if (colorCode) {
      price = parseFloat(optWrapper.colorList[colorCode].price || optWrapper.price || 0)
    // all other OPTs
    } else {
      if (optWrapper.price) {
        price = parseFloat(optWrapper.price);
      }
    }

    // hardcoded steeringwheel price management (v1 cars only)
    if (optWrapper.id === "SWWL" || optWrapper.id === "SWEA") {
      price = 0;
    } else if (optWrapper.id === "SWWD") {
      var SWWD = this.Opts().getOpt("SWWL", true);
      price = parseFloat( (SWWD && SWWD.isSelected() && SWWD.price) || optWrapper.price || 0);
    }

    return price;
  };


  // Price helper to calculate base price of Commercial pack
  Engine.prototype.calculatePackBasePrice = function (idPack) {
    if (typeof idPack === "string") {
      var optWrapperPack = this.Opts().getOpt(idPack);
    } else {
      var optWrapperPack = idPack;
    }

    Log.d("Calculating pack base price for ", idPack);

    var pricePack = 0;
    pricePack = this.calculateOptPrice(optWrapperPack);

    Log.d(idPack + " starting from: " + pricePack);

    // calculating price due to sets components
    if (optWrapperPack.sets) {
      for (var z = 0; z < optWrapperPack.sets.length; z++) {
        var set = optWrapperPack.sets[z];

        // choosing defaultOfSet following this fallback chain:
        // 1- first selected item
        // 2- opt with preset (if selectable)
        // 3- first selectable item from left to right
        // 4- opt with preset (even it not selectable)
        // 5- first item (if preset doesnt exist)

        var defaultOfSet = null;
        var alreadySelectedOfSet = null;
        var firstSelectableItemOfSet = null;
        var selectablePresetOfSet = null;
        var unselectablePresetOfSet = null;
        var firstItemOfSet = null;

        var setSize = set.length;

        for (var x = setSize-1; x >= 0; x--) {
          var opt = set[x];
          var optWrapper = this.Opts().getOpt(opt.id);
          var optStatus = this.renderingStatusByOPT(opt.id);

          if (_.isEmpty(alreadySelectedOfSet)) {

            if (optWrapper.isSelected()) {
              alreadySelectedOfSet = opt;
            } else {

              if(_.isEmpty(selectablePresetOfSet)){

                if (optStatus.selectable){

                  if (opt.preset == "1"){
                    selectablePresetOfSet = opt;
                  } else {
                    firstSelectableItemOfSet = opt;
                  }
                } else {

                  if (opt.preset == "1"){
                    unselectablePresetOfSet = opt;
                  }
                }
              }
            }

          }
        }

        defaultOfSet = alreadySelectedOfSet || selectablePresetOfSet || firstSelectableItemOfSet || unselectablePresetOfSet; // this is an opt

        if (!defaultOfSet){
          defaultOfSet = set[0];
          Log.warn("Price of pack "+ idPack + " has been adjusted with price of " + defaultOfSet.id + " (first opt of set=" + _.pluck(set, "id") + ") because no opt is selectable and there is no preset");
        }

        var optWrapper = this.Opts().getOpt(defaultOfSet.id);

        // counting default price only if flagged as price=I
        if (defaultOfSet.price === 'I' && !optWrapper.isPack()) {
          var optPrice = this.calculateOptPrice(defaultOfSet.id);

          pricePack += optPrice;

          Log.d("+ " +  defaultOfSet.id + " price of " + optPrice);

        }

      }
    }

    // check opt standalone  with price 'i'
    if (optWrapperPack.standalone) {
      for (var z = 0; z < optWrapperPack.standalone.length; z++) {
        var opt = optWrapperPack.standalone[z];
        if (opt.price === 'I') {
          var optPrice = this.calculateOptPrice(opt.id);
          pricePack += optPrice;
          Log.d("+ " +  opt.id + " price of " + optPrice);

        }
      }
    }

    return pricePack;
  };


  // Calculate total car price with basecar + pack with default selections + standalone external
  Engine.prototype.calculateTotalCarPriceWithDefaultPack = function () {

    // TODO remove all console.log

    var selectedPacks = this.Configuration._selectedPacks;
    var selectedOptsNotPack = this.Opts().DB({selected: "1", pack: "0"}).select("id");
    //console.log(selectedOptsNotPack);

    // Build a list of OPT that are in configuration (selected = '1') due to a Pack inclusion
    var selectedOptsInPack = [];
    selectedOptsInPack = selectedOptsInPack.concat(selectedOptsInPack, this.Configuration._singleStandaloneInSelectedPacks);
    selectedOptsInPack = selectedOptsInPack.concat(selectedOptsInPack, this.Configuration._selectedChoicesOfSetInSelectedPacks);

    var price = 0;

    // get base price
    var baseCarPrice = parseFloat(this.Configuration.data.general.price);
    if (!isNaN(baseCarPrice)) {
      price += baseCarPrice;
    }
    //console.log("Base price: ", baseCarPrice);

    // get base packs prices (considered default only)
    for (var i = 0; i < selectedPacks.length; i++) {
      var packID = selectedPacks[i].id;
      var packBasePrice = this.calculatePackBasePrice(packID);
      //console.log("Pack base price: ", packID, packBasePrice);
      price += packBasePrice;
    }

    // get prices of selected OPTs standalone external (not included in pack)
    for (var z = 0; z < selectedOptsNotPack.length; z++) {
      var optID = selectedOptsNotPack[z];

      if ($.inArray(optID, selectedOptsInPack) === -1) {
        var optWrapper = this.Opts().getOpt(optID);
        var optPrice = this.calculateOptPrice(optWrapper.id);

        // if opts has an associated quantity, its price need to be multiply
        if (optWrapper.allowMultiple === '1' && optWrapper.quantity > 1) {
          var priceWithQuantity = optPrice * optWrapper.quantity;
          optPrice = priceWithQuantity;
        }
        //console.log("External opt price ", optWrapper.id, optPrice);
        price += optPrice;
      }
    }

    return price;
    //console.log("Defualt total car price ", price);
  };


  // Calculate adjustment price as substraction between TotalCarPrice and TotalCarPriceWithDefaultPack
  Engine.prototype.calculateAdjustmentCarPrice = function () {
    var totalCarPrice = this.calculateTotalCarPrice();
    var totalCarPriceWithDefaultPack = this.calculateTotalCarPriceWithDefaultPack();
    var adjustmentCarPrice = totalCarPrice - totalCarPriceWithDefaultPack;

    return adjustmentCarPrice;
  };

  // Calculate total car price with basecar + pack with current selections + standalone external
  Engine.prototype.calculateTotalCarPrice = function () {

    // reset global data structure used by Engine.calculatePackDynamicPrice()
    optsParsedForDynamicPackPrice = [];

    //var selectedPacks = this.Configuration._selectedPacks;

    // MACC-1914
    var selectedPacks = this.Opts().DBSimulated({selected: '1', pack: '1'});
    // var selectedOptsNotPack = this.Opts().DB({selected:"1", pack:"0"}).select("id");
    var selectedOptsNotPack = this._selectedOptsNotPack = _.pluck(this.Opts().DBSimulated({
      selected: "1",
      pack: "0"
    }), "id");
    var parsedOpts = [];
    var price = 0;
    var baseCarPrice = parseFloat(this.Configuration.data.general.price);
    if (!isNaN(baseCarPrice)) {
      price += baseCarPrice;
    }

    // starting checking price from Pack (which price is dynamic)
    for (var i = 0; i < selectedPacks.length; i++) {
      var optPackWrapper = this.Opts().getOpt(selectedPacks[i].id);

      var pricePack = 0;
      pricePack = this.calculateOptPrice(optPackWrapper);
      parsedOpts.push(optPackWrapper.id);

      // check opt standalone from sets with price 'i' and selected in config
      if (optPackWrapper.sets) {
        for (var z = 0; z < optPackWrapper.sets.length; z++) {
          var set = optPackWrapper.sets[z];
          for (var y = 0; y < set.length; y++) {
            var optInSet = set[y];
            // Opt Standalone used for price calc only if selected, if price = "I" and if not already parsed
            if ($.inArray(optInSet.id, selectedOptsNotPack) !== -1 && $.inArray(optInSet.id, parsedOpts) === -1 && optInSet.pack === '0') {
              if (optInSet.price === 'I') {
                var optPrice = this.calculateOptPrice(optInSet.id);
                pricePack += optPrice;
                // console.info("1", optPackWrapper.id, price + pricePack);
              }
              parsedOpts.push(optInSet.id);
            }
          }
        }
      }

      // TODO: check this
      // check single opt standalone with price 'i' and selected in config
      if (optPackWrapper.standalone) {
        for (var z = 0; z < optPackWrapper.standalone.length; z++) {
          var optSingleStandalone = optPackWrapper.standalone[z];
          if ($.inArray(optSingleStandalone.id, selectedOptsNotPack) !== -1 && $.inArray(optSingleStandalone.id, parsedOpts) === -1 && optSingleStandalone.pack === '0') {
            if (optSingleStandalone.price === 'I') {
              var optPrice = this.calculateOptPrice(optSingleStandalone.id);
              pricePack += optPrice;
              // console.info("2", optPackWrapper.id, price + pricePack);
            }
            // add optSingleStandalone to parsed
            parsedOpts.push(optSingleStandalone.id);
          }
        }
      }

      // add price of every pack to base price
      price += pricePack;
    }

    // continue checking opt standalone not touched by Pack
    for (var x = 0; x < selectedOptsNotPack.length; x++) {
      var selectedOptId = selectedOptsNotPack[x];
      if ($.inArray(selectedOptId, parsedOpts) === -1) {
        var optPrice = this.calculateOptPrice(selectedOptId);
        var optWrapper = this.Opts().getOpt(selectedOptId);
        if (optWrapper.allowMultiple === '1' && optWrapper.quantity > 1) {
          var priceWithQuantity = optPrice * optWrapper.quantity;
          optPrice = priceWithQuantity;
        }
        price += optPrice;
        // console.info("3", selectedOptId, price);
        parsedOpts.push(selectedOptId);
      }
    }

    //console.log("Prezzo totale", price);

    return price;
  };

  //calculate pack price with current selections
  Engine.prototype.calculatePackDynamicPrice = function (optPackId) {

    Log.d("Calculating dynamic price for ", optPackId);

    //opts selected as standalone
    var selectedOptsNotPack = _.pluck(this.Opts().DBSimulated({selected: "1", pack: "0"}), "id");
    var optPackWrapper = this.Opts().getOpt(optPackId);
    var pricePack = this.calculateOptPrice(optPackWrapper);

    Log.d(optPackId + " starting from: " + pricePack);

    if (optPackWrapper.sets) {
      for (var z = 0; z < optPackWrapper.sets.length; z++) {
        var set = optPackWrapper.sets[z];
        for (var y = 0; y < set.length; y++) {
          var optInSet = set[y];
          // Opt Standalone used for price calc only if selected, if price = "I" and if not already parsed and if opt is not a pack
          if ($.inArray(optInSet.id, selectedOptsNotPack) !== -1 && $.inArray(optInSet.id, optsParsedForDynamicPackPrice) === -1 && optInSet.pack === '0') {
            if (optInSet.price === 'I') {
              var optPrice = this.calculateOptPrice(optInSet.id);
              pricePack += optPrice;
              Log.d("+ " +  optInSet.id + " price of " + optPrice);
            }
            optsParsedForDynamicPackPrice.push(optInSet.id);
          }
        }
      }
    }

    // check single opt standalone with price 'i' and selected in config (explicits)
    if (optPackWrapper.standalone) {
      for (var z = 0; z < optPackWrapper.standalone.length; z++) {
        var optSingleStandalone = optPackWrapper.standalone[z];
        if ($.inArray(optSingleStandalone.id, selectedOptsNotPack) !== -1 && $.inArray(optSingleStandalone.id, optsParsedForDynamicPackPrice) === -1 && optSingleStandalone.pack === '0') {
          if (optSingleStandalone.price === 'I') {
            var optPrice = this.calculateOptPrice(optSingleStandalone.id);
            pricePack += optPrice;
            Log.d("+ " +  optSingleStandalone.id + " price of " + optPrice);

          }
          // add optSingleStandalone to parsed
          optsParsedForDynamicPackPrice.push(optSingleStandalone.id);
        }
      }
    }

    return pricePack;

  };


  // Get taxes for car
  Engine.prototype.getTaxes = function () {
    var taxes = this.Configuration.data.taxes;
    var taxesPrice = parseFloat(0);

    if (taxes) {

      for (var i = 0, l = taxes.length; i < l; i++) {
        var item = taxes[i];
        taxesPrice = parseFloat(taxesPrice + parseInt(item.value, 10));
      }
      ;
    }

    return taxesPrice;
  };


  // Calculate total car prices with taxes
  Engine.prototype.calculateTotalCarPriceWithTaxes = function () {
    return this.calculateTotalCarPrice() + this.getTaxes();
  }

})(jQuery);



(function($){

  var Log = new Logger("EngineRecom");
  Log.mute( ! window.LOG_ENGINE );

  Engine.prototype.Recommendation = function(){
    return this._recommendation || (this._recommendation = new _Recommendation(this) );
  };

  Engine.prototype.parseRecommendation = function(data){

    this.Recommendation()._parse( data );

  };

  function _Recommendation(engine) {
    var self = this;

    this.data = [];

    this.all = function(){
      return this.data;
    };

    this._parse = function(data) {
      this.data = [];
      $.each(data, function(i, obj){

        var opt = engine.Opts().getById( obj.id );

        opt = $.extend( {}, opt, obj );

        self.data.push( opt );

      });
    };


    this.filter = function(forceRemove){
      engine.disableEvents();
      var original_price = engine.calculateTotalCarPrice(), result_opts = [], opts_to_remove = [];

      for ( var i = this.data.length - 1; i >= 0; i-- ) {
        var opt = this.data[i];
        var result = engine.update( opt.id, "selected", "1", false);

        if ( result ) {

          var new_price = engine.calculateTotalCarPrice();
          if ( new_price >= original_price ) {
            // opt can be correctly applied
            opt.enabled = true;
          } else {
            // this opt cannot be applied due to reducing price
            opt.enabled = false;
          }

        } else {
          // OPT cannot be applied
          opt.enabled = false;
        }

        if (forceRemove && !opt.enabled){
          this.data.splice(i, 1);
          continue;
        }

        result_opts.push( opt );
      }

      engine.enableEvents();

      return result_opts;
    };

  };

})(jQuery);

/*  renderingStatusByOPT(idOPT)
 *  calculate randering status of each OPT (both standlone and pack opt)
 *  returns an object like this: {"visible": true/false, "selectable": true/false }
 *  Check here for specs http://mantis.maserati.com/view.php?id=612
 */
(function ($) {
  'use strict';

  var Log = new Logger("EngineGui");
  Log.mute(!window.LOG_ENGINE);

  Engine.prototype.initEvent = function () {
    $(this).on("_loaded", $.proxy(function (e, data) {
      _prepareData.apply(this, arguments);
      $(this).trigger("ready", [data]);
    }, this));

    $(this).on("_processed", $.proxy(function (e, optW, allWs, colorIdent) {
      _prepareData.apply(this, arguments);
      $(this).trigger("completed", [optW, allWs, colorIdent]);
    }, this));

    $(this).on("transactionRollback", $.proxy(function (e, data) {
      _prepareData.apply(this, arguments);
    }, this));
  };


  var _prepareData = Engine.prototype.__prepareData__ = function (e, data) {
    // Data used by gui helpers

    // selected OPTs (not pack) in configuration (checking selected status in taffy DB)
    var selectedOptsID = _.pluck(this.Opts().DBSimulated({selected: '1', pack: '0'}), "id");


    // Selected packs in configuration
    var selectedPacks = this.Opts().DBSimulated({selected: '1', pack: '1', must: '0'});
    var selectedPacksID = [];
    for (var x = 0; x < selectedPacks.length; x++) {
      selectedPacksID.push(selectedPacks[x].id);
    }
    this.Configuration._selectedPacks = selectedPacks;

    // implicits included in selected packs
    var selectedImplicits = [];
    for (var z = 0; z < selectedPacks.length; z++) {
      var selectedPack = selectedPacks[z];
      if (selectedPack.implicits) {
        for (var y = 0; y < selectedPack.implicits.length; y++) {
          var implicit = selectedPack.implicits[y];
          if ($.inArray(implicit, selectedImplicits) === -1) {
            selectedImplicits.push(implicit);
          }
        }
      }
    }

    this.Configuration._selectedImplicits = selectedImplicits;

    // standalone included (not only the selected) in selected packs (both as single or in sets)
    var singleStandaloneInSelectedPacks = [];
    var standaloneOfSetInSelectedPacks = [];

    // selected ( only the selected!!!)  choices of sets included in selected packs
    var selectedChoicesOfSetInSelectedPacks = [];

    for (var z = 0; z < selectedPacks.length; z++) {

      var selectedPack = selectedPacks[z];

      // standalone as single opt
      if (selectedPack.standalone) {
        for (var y = 0; y < selectedPack.standalone.length; y++) {
          var standaloneOpt = selectedPack.standalone[y];
          if ($.inArray(standaloneOpt.id, singleStandaloneInSelectedPacks) === -1) {
            singleStandaloneInSelectedPacks.push(standaloneOpt.id);
          }
        }
      }

      // standalone as set of opt
      if (selectedPack.sets) {
        for (var y = 0; y < selectedPack.sets.length; y++) {
          var set = selectedPack.sets[y];
          for (var x = 0; x < set.length; x++) {
            var standaloneOpt = set[x];

            // collected in standaloneOfSetInSelectedPacks if is included in a pack and not already considered
            if (standaloneOpt.pack !== '1' && $.inArray(standaloneOpt.id, standaloneOfSetInSelectedPacks) === -1) {
              standaloneOfSetInSelectedPacks.push(standaloneOpt.id);
            }

            // collected in selectedChoicesOfSetInSelectedPacks if is included in a pack, is selected and not already considered
            if (standaloneOpt.pack !== '1' &&
              $.inArray(standaloneOpt.id, selectedChoicesOfSetInSelectedPacks) === -1 &&
              $.inArray(standaloneOpt.id, selectedOptsID) !== -1) {
              selectedChoicesOfSetInSelectedPacks.push(standaloneOpt.id);
            }

          }
        }
      }
    }


    this.Configuration._singleStandaloneInSelectedPacks = singleStandaloneInSelectedPacks;
    this.Configuration._standaloneOfSetInSelectedPacks = standaloneOfSetInSelectedPacks;
    this.Configuration._selectedChoicesOfSetInSelectedPacks = selectedChoicesOfSetInSelectedPacks;


    // it's a list of OPT that cannot be broken. They can be:
    // - opt of selected pack
    // - opt implicit in a selected pack
    // - opt standalone included in a selected pack
    this.Configuration._optsToPreserveFromBroken = [];
    this.Configuration._optsToPreserveFromBroken = this.Configuration._optsToPreserveFromBroken.concat(this.Configuration._selectedImplicits);
    this.Configuration._optsToPreserveFromBroken = this.Configuration._optsToPreserveFromBroken.concat(this.Configuration._singleStandaloneInSelectedPacks);
    //this.Configuration._optsToPreserveFromBroken = this.Configuration._optsToPreserveFromBroken.concat(this.Configuration._standaloneOfSetInSelectedPacks);
    this.Configuration._optsToPreserveFromBroken = this.Configuration._optsToPreserveFromBroken.concat(selectedPacksID);

    // list all id of opt involved in E rules with Packs
    var optsInvolvedInERules = this.Rules().getRulesACE({
      type: "E",
      optChild: this.Configuration._optsToPreserveFromBroken
    }).distinct("optFather");

    // optsToAvoid that are also in this._optsToPreserveFromBroken should not be considered as dangerous for pack
    var optsToAvoid = [];

    for (var i = 0; i < optsInvolvedInERules.length; i++) {
      var optInvolvedInERules = optsInvolvedInERules[i];

      if ($.inArray(optInvolvedInERules, this.Configuration._optsToPreserveFromBroken) === -1) {
        optsToAvoid.push(optInvolvedInERules);
      }
    }

    this.Configuration._optsCanBreakConfig = optsToAvoid;

  }


  /**
   * method to return to GUI the rendering status of a given Id base on config
   * @param idOPT
   * @returns {{visible: boolean, selectable: boolean, labelKey: string, priceVisible: boolean, selectedInPack: boolean}}
   */
  Engine.prototype.renderingStatusByOPT = function (idOPT) {

    var optWrapper = this.Opts().getOpt(idOPT);

    // renderingStatus defaults, they can be overriden by more specific rules
    var renderingStatus = {
      visible: false,
      selectable: true,
      labelKey: "",
      priceVisible: true,
      selectedInPack: false,
      selected: optWrapper.isSelected(),
      colorSelected: optWrapper.selectedColor(),
      availableColors: optWrapper.getAvailableColors()
    };

    // variable used to check if selectability has been parsed or need to be checked via click-simulation method
    var selectabilityParsed = false;

    // disable event in order to use click-simulation method
    this.disableEvents();

    // temp save current log level and silent logs
    var previousLevel = LEVEL;
    LEVEL = 0;

    // OPT commercial/compulsory must or TECH must not visible
    if (optWrapper.isMust() && optWrapper.isHidden()) {

      renderingStatus['visible'] = false;
      renderingStatus['selectable'] = false;
      renderingStatus['priceVisible'] = false;
      selectabilityParsed = true;
    }


    // OPT commercial must but visible (except Colored OPTs like INT)
    if (optWrapper.isMust() && !optWrapper.isHidden() && $.inArray(optWrapper.group, ["MEC", "BOE", "FUS"]) > -1) {

      renderingStatus['visible'] = true;
      renderingStatus['labelKey'] = "OPT_INCLUDED_AS_MUST";
      renderingStatus['selectable'] = false;
      renderingStatus['priceVisible'] = false;
      selectabilityParsed = true;
    }

    //ACCESSORIES AS MUST - MACC2295
    else if(optWrapper.isMust() && !optWrapper.isHidden() && $.inArray(optWrapper.group, ["ACC_AN"]) > -1){
      renderingStatus['visible'] = true;
      renderingStatus['labelKey'] = "ACC_INCLUDED_AS_MUST";
      renderingStatus['selectable'] = false;
      renderingStatus['priceVisible'] = false;
      selectabilityParsed = true;
    }

    // OPT warranty and accessory
    else if (optWrapper.id[0] == 'a') {
      renderingStatus['visible'] = true;
      renderingStatus['selectable'] = true;
      selectabilityParsed = true;
    }

    // PACK (not considering Compulsory Pack)
    else if (optWrapper.isPack() && !(optWrapper.isMust())) {

      renderingStatus['visible'] = true;
      renderingStatus['priceVisible'] = true;
    }

    // OPT: standalone/implicit/explicit/sets
    else if (!optWrapper.isPack()) {

      // OPT only implicit: OPT that cannot be selected and visible outside packs
      if (optWrapper.isOnlyImplicit()) {

        renderingStatus['visible'] = false;
        renderingStatus['selectable'] = false;
        renderingStatus['priceVisible'] = false;
        renderingStatus['selectedInPack'] = false;
        selectabilityParsed = true;
      }

      // OPT in SET of selected pack
      else if (_.contains(this.Configuration._standaloneOfSetInSelectedPacks, optWrapper.id)) {

        renderingStatus['visible'] = true;
        renderingStatus['priceVisible'] = false;
        renderingStatus['selectedInPack'] = optWrapper.isSelected();
        renderingStatus['labelKey'] = optWrapper.isSelected() ? "OPT_INCLUDED_IN_PACK" : "";

      }

      // OPT Standalone that is implicit or single standalone in selected pack
      else if (_.contains(this.Configuration._selectedImplicits, optWrapper.id) ||
        _.contains(this.Configuration._singleStandaloneInSelectedPacks, optWrapper.id)) {

        renderingStatus['selectable'] = false;
        renderingStatus['visible'] = true;
        renderingStatus['priceVisible'] = false;
        renderingStatus['labelKey'] = "OPT_INCLUDED_IN_PACK";
        renderingStatus['selectedInPack'] = optWrapper.isSelected();
        selectabilityParsed = true;
      }

      // OPT Standalone that can break actual configuration (incompatible with selected packs)
      else if (_.contains(this.Configuration._optsCanBreakConfig, optWrapper.id)) {

        renderingStatus['selectable'] = false;
        renderingStatus['visible'] = !optWrapper.isHidden();
        renderingStatus['labelKey'] = "OPT_NOT_AVAILABLE_WITH_PACK";
        renderingStatus['priceVisible'] = false;
        renderingStatus['selectedInPack'] = false;
        selectabilityParsed = true;
      }

      // all other OPT standalone
      else {
        renderingStatus['visible'] = !optWrapper.isHidden();
        renderingStatus['priceVisible'] = !optWrapper.isHidden();
        renderingStatus['selectedInPack'] = false;

        if (!_.isEmpty(optWrapper.colorList) && optWrapper.isSelected()) {
          renderingStatus['selectable'] = true;
          selectabilityParsed = true;
        }
      }
    }

    // click-simulation method used only if selectability is not already parsed in other way
    // before to use click-simulation we try to simple check Q9 rules (isSelectable method)
    if (!selectabilityParsed) {

      if (!optWrapper.isSelectable()) {
        renderingStatus['selectable'] = false;
      } else {

        if (optWrapper.isSelected() && optWrapper.requiredByColorConfig()) {
          renderingStatus['selectable'] = false;
        } else {
          if (_.contains(['RIMS', 'CAL'], optWrapper.group)) {
            renderingStatus['selectable'] = true;
          } else {
            renderingStatus['selectable'] = this.update(optWrapper.id, "selected", optWrapper.isSelected() ? "0" : "1", false) ? true : false;
          }
        }

      }
    }

    LEVEL = previousLevel;
    this.enableEvents();

    return renderingStatus;
  };

  /**
   * Helper to clean carConfigurationStorage by implicits OPTs in order to send to Maserati services a configuration
   * without implicits since they must not be considered as selected OPTs. It's used for example to pass a cleaned
   * configuration to delaer service or to pdf service
   * @param storage
   * @returns {*}
   */
// Engine.prototype.removeImplicitsFromCarConfigurationStorage = function(ccStorage){
//
//   var selectedImplicits = this.Configuration._selectedImplicits;
//   var carConfigurationStorageWithoutImplicits = $.extend(true, {}, ccStorage);
//
//   for (var k=0; k < selectedImplicits.length; k++){
//     var optWrapper = this.Opts().getOpt(selectedImplicits[k]);
//
//     if (carConfigurationStorageWithoutImplicits.hasOwnProperty(optWrapper.group)) {
//       var position = $.inArray(optWrapper.id, carConfigurationStorageWithoutImplicits[optWrapper.group]);
//
//       if ( position !== -1){
//         carConfigurationStorageWithoutImplicits[optWrapper.group].splice(position,1);
//
//         // if no more opt in current section of the carCarConfigurationStorage the section have to be removed
//         if (carConfigurationStorageWithoutImplicits[optWrapper.group].length < 1) {
//           delete carConfigurationStorageWithoutImplicits[optWrapper.group];
//         }
//       }
//     }
//   }
//
//   return carConfigurationStorageWithoutImplicits;
// };


})
(jQuery);

